.Payment_History_main_section {
    position: relative;
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
    z-index: 9;
    overflow-y: scroll;
    /* border: 1px solid red; */
}

.Payment_History_main_section::-webkit-scrollbar {
    display: none;
}

.Payment_History_Title {
    font-family: "Poppins-Regular";
    font-weight: 600;
    font-size: 35px;
}

.search_main_section {
    position: relative;
    display: inline-block;
    width: 60%;
}

.payment_history_searchbar {
    width: 100% !important;
    height: 40px !important;
    background: rgba(255, 255, 255, 0.8) !important;
    border-radius: 17px !important;
    padding-right: 16% !important;
    border: none !important;
    box-shadow: none !important;
}

.payment_history_searchbar:focus {
    border: none !important;
    box-shadow: none !important;
}

.payment_history_searchbar::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}

.search_icon {
    position: absolute;
    top: 18%;
    right: 6%;
    color: rgba(0, 0, 0, 0.5);
    font-size: 25px;
}

.history_detail_row {
    border-radius: 20px;
    padding: 4px 0px 4px 71px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 5px 8px 6px rgba(59, 64, 86, 0.15);
}

.history_detail_icon {
    height: 40px;
    width: 40px;
    background-color: #E53865;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.history_detail_icon img {
    width: 60%;
    height: 60%;
}

.history_detail_section {
    width: 100%;
    /* display: flex; */
    /* justify-content: space-around; */
}

.history_detail_id {
    color: #323236;
    font-family: "Poppins-Regular";
    font-size: 20px;
    font-weight: 500;
}

.history_detail_date {
    color: #83848B;
    font-family: "Poppins-Regular";
    font-size: 16px;
    font-weight: 500;
}

.history_detail_price {
    color: #0BBC07;
    font-family: "Poppins-Regular";
    font-size: 20px;
    font-weight: 500;
}

.Payment_History_sticky_first_section {
    height: 14%;
    position: sticky;
    border: 1px solid transparent;
    top: 0;
}

.Payment_History_sticky_second_section {
    height: 86%;
    position: sticky;
    border: 1px solid transparent;
    bottom: 0;
    overflow-y: scroll;
}

.Payment_History_sticky_second_section::-webkit-scrollbar {
    display: none;

}

.newpaymentrefrralicon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    overflow: hidden;
    height: 40px;
    border-radius: 50px;
}
.payemtuserimage{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 280px) {
    .history_detail_id {
        font-size: 12px !important;
    }
    .history_detail_price {
        font-size: 13px !important;
    }
    .history_detail_date {
        font-size: 10px !important;
    }

}

@media(min-width:280px) and (max-width:767px) {
    .Payment_History_main_section {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .Payment_History_sticky_first_section {
        height: 23%;
        position: sticky;
        /* border: 1px solid; */
        top: 0;
    }

    .Payment_History_sticky_second_section {
        height: 77%;
        position: sticky;
        /* border: 1px solid; */
        bottom: 0;
        overflow-y: scroll;
    }

    .payment_history_searchbar {
        width: 100% !important;
        padding-right: 14% !important;
        border-radius: 12px !important
    }

    .Payment_History_Title {
        font-size: 20px;
    }

    .search_main_section {
        width: 93%;
    }

    .history_detail_row {
        padding: 4px 0px 4px 5px;
        border-radius: 14px;
    }
    .history_detail_icon {
        width: 45px;
    }
    .history_detail_id {
        font-size: 15px;
    }

    .history_detail_date {
        font-size: 11px;
    }

    .history_detail_price {
        font-size: 15px;
    }
     
}

@media(min-width:768px) and (max-width:1023px) {
    .Payment_History_main_section {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }
    .search_main_section {
        width: 85%;
    }

    .Payment_History_Title {
        font-size: 30px;
    }

    .payment_history_searchbar {
        width: 100% !important;
        padding-right: 18% !important;
    }

    .history_detail_row {
        padding: 4px 0px 4px 20px;
    }

    .history_detail_id {
        font-size: 18px;
    }

    .history_detail_date {
        font-size: 14px;
    }

    .history_detail_price {
        font-size: 18px;
    }
}

@media(min-width:1024px) and (max-width:1299px) {
    .payment_history_searchbar {
        width: 100% !important;
        padding-right: 20% !important;
    }
    .history_detail_id {
        font-size: 18px;
    }

    .history_detail_date {
        font-size: 14px;
    }

    .history_detail_price {
        font-size: 18px;
    }
}

@media(min-width:1300px) and (max-width:1399px) {
    .history_detail_id {
        font-size: 18px;
    }

    .history_detail_date {
        font-size: 14px;
    }

    .history_detail_price {
        font-size: 18px;
    }
}
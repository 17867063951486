.Blocked_User_section_contain {
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
}
.addnewreferraluserlist{
    height: 80dvh;
    height: calc(80dvh - var(--banner-div-height));
}

.Blocked_User_section_title {
    height: 10vh;
    height: 10dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Blocked_User_title {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
}

.Blocked_User_section_slider {
    height: 70vh;
    height: calc(70dvh - var(--banner-div-height));
    overflow-y: scroll;
}

.Blocked_User_section_slider::-webkit-scrollbar {
    display: none;
}

.Blocked_User_main_row {
    background-color: #FFFFFFE5;
    border-radius: 10px;
    box-shadow: 0px 7px 7px 0px #3B405626;

}

.Blocked_User_slider_first_section {
    width: 10%;
    /* border: 1px solid greenyellow; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.Blocked_User_slider_second_section {
    width: 90%;
    /* border: 1px solid red; */
}

.Blocked_User_img_section {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.Blocked_User_img {
    width: 100%;
    height: 100%;
}

.Blocked_User_slider_name_city_section {
    width: 65%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Blocked_User_slider_name {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    ;
}

.Blocked_User_slider_city {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}

.Blocked_User_slider_button_section {
    width: 35%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Blocked_User_slider_button {
    border: none !important;
    background-color: rgba(229, 56, 101, 0.9);
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 16px;
    color: #FFFFFF;
}

.Blocked_User_Data_Count {
    color: #E53865;
}

.What_new img {
    max-width: 100% !important;
}
.bagesclassset{
    font-weight: 200 !important;
    padding: 5px !important;
}
.myrefaluserlist{
    padding: 10px 35px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
}
@media(max-width:280px) {
    .Blocked_User_slider_first_section {
        width: 20% !important;
    }
    .Blocked_User_img_section {
        height: 40px !important;
        width: 40px !important;
    }
    .Blocked_User_slider_name_city_section {
        width: 60% !important;
    }
    .Blocked_User_slider_button {
        padding: 6px 14px !important;
        font-size: 10px !important;
    }

}

@media(min-width:280px) and (max-width:767px) {
    .Blocked_User_section_contain {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }
    .addnewreferraluserlist{
          /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height) + 410px);
    }

    .Blocked_User_section_title {
        height: 10vh;
        height: 10dvh;
        justify-content: center;
    }

    .Blocked_User_section_slider {
        /* height: calc(63dvh - var(--banner-div-height)); */
        height: calc(72dvh - var(--banner-div-height));
    }

    .Blocked_User_title {
        font-weight: 600;
        font-size: 25px;
        color: #000000;
    }

    .Blocked_User_slider_first_section {
        width: 15%;
        /* border: 1px solid greenyellow; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Blocked_User_slider_second_section {
        width: 85%;
        /* border: 1px solid red; */
    }

    .Blocked_User_slider_name_city_section {
        /* width: 65%; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* border: 1px solid red; */
    }

    .Blocked_User_slider_name {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
    }

    .Blocked_User_slider_city {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
    }

    .Blocked_User_slider_button {
        padding: 6px 16px;
        font-size: 11px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .Blocked_User_section_contain {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }
    .addnewreferraluserlist{
         /* height: calc(73dvh - var(--banner-div-height)); */
         height: calc(82dvh - var(--banner-div-height) + 406px);
    }

    .Blocked_User_section_slider {
        /* height: calc(63dvh - var(--banner-div-height)); */
        height: calc(72dvh - var(--banner-div-height));
    }
}
.modal-content {
    background-color: transparent !important;
    height: auto !important;
    border: none !important;
    border-radius: 0px !important;
    width: 510px !important;
    position: relative !important;
}

.main_blur_section {
    height: 100%;
    width: 75%;
    background-color: rgba(217, 217, 217, 0.7) !important;
    right: 0%;
    border: none;
    position: absolute;
    top: 0%;
}

.close_icon {
    position: absolute;
    right: 1%;
    top: 1%;
    font-size: 29px;
    cursor: pointer;
}

.main_content_section {
    height: 90%;
    width: 100%;
    border: none;
    margin: 5% auto;
    display: flex
}

.first_section {
    width: 50%;
    border: none;
    z-index: 999;
}

.first_section img {
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 14px;
}

.second_section {
    width: 50%;
    border: 0px solid red;
    z-index: 999;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3%;
}

.title_section_name_title {
    font-size: 26px;
    max-width: 9ch; /* Approx 10 characters width */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.title_section_custom_width{
    width: auto  !important;
    max-width: 90% !important;
}

.title_section_name_title-home {
    font-size: 26px;
    /* width: 90%; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.title_section_name_title_main_section {
    width: 80%;
}

.title_section_like_unlike_main {
    width: 20%;
    text-align: right;
}

.title_section .title_section_name_title a {
    color: #E53865;
    margin: 0%;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    text-decoration: none;
}

.heart_icon {
    height: 30px;
    width: 30px;
    color: white;
}

.location_section {
    display: flex;
    cursor: pointer;
}

.location_section img {
    height: 25px;
}

.location_section h5 {
    font-size: 21px;
    color: white;
    font-family: 'Poppins-Regular';
    font-weight: 600;
}

.introduction_section h4 {
    font-size: 16px;
    font-family: 'Poppins-Regular' !important;
    font-weight: 700 !important;
    color: #000000;
    margin-bottom: 0%;
}

.introduction_section p {
    font-size: 11px;
    font-family: 'Poppins-Regular' !important;
    font-weight: 400;
    color: #383838 !important;
}

.intersts_section h4 {
    font-size: 16px;
    font-family: 'Poppins-Regular' !important;
    font-weight: 700 !important;
    color: #000000;
    margin-bottom: 0%;
}

.music_section {
    border-radius: 3px;
    padding: 1px 6px 3px 6px;
    background-color: rgba(229, 56, 101, 0.5);
}

.music_name {
    font-size: 13px;
    font-family: 'DMSans-Regular' !important;
    font-weight: 500 !important;
    color: #000000;
}

.sport_section {
    border-radius: 3px;
    padding: 1px 6px 3px 6px;
    background-color: rgba(229, 56, 101, 0.5);
    margin-left: 2%;
}

.sport_name {
    font-size: 13px;
    font-family: 'DMSans-Regular' !important;
    font-weight: 500 !important;
    color: #000000;
}

.Show_More_Interests {
    font-size: 13px;
    font-family: 'DMSans-Regular' !important;
    font-weight: 500 !important;
    color: #000000;
    text-decoration: none !important;
}

.Show_More_Interests:hover {
    color: #000000;
    text-decoration: none !important;
}


.star_section {
    display: flex;
    margin-top: 7%;
}

.first_star {
    width: 29px;
    height: 29px;
    border: 1.25px solid rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    /* background-color: transparent; */
    margin-left: 0%;
    cursor: pointer;
}

.second_star {
    width: 29px;
    height: 29px;
    border: 1.25px solid rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    /* background-color: transparent; */
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.star_icon {
    color: white;
}

.fav_icon {
    color: #FFCC33;
}

.third_star {
    width: 29px;
    height: 29px;
    border: 1.25px solid rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    /* background-color: transparent; */
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.content_top_chat_icon{
    width: 29px;
    height: 29px; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}

.camera_icon {
    color: white;
}

.modal_Chatpage_luSend {
    color: white;
    height: 70%;
    width: 70%;
}

@media(max-width:280px){
    .modal-content {
        width: 100% !important;
    }
    .main_blur_section {
        height: 80%;
        width: 100%;
        top: 20%;
    }
    .main_content_section {
        height: 90%;
        width: 100%;
        margin: 3% auto;
        flex-direction: column;
    }
    .second_section {
        width: 100%;
    }
    .first_section {
        width: 45%;
        margin-left: 27.5%;
    }
    .title_section h1 {
        margin: 0%;
        font-size: 22px;
        width: 95%;
    }
    .location_section img {
        height: 21px;
    }
    .location_section h5 {
        font-size: 17px;
    }
    .introduction_section h4 {
        font-size: 14px;
    }
    .intersts_section h4 {
        font-size: 14px;
    }
    .music_section {
        padding: 1px 4px 3px 4px;
    }
    .music_name {
        font-size: 12px;
    }
    .Show_More_Interests {
        font-size: 12px;
    }
    .star_section {
        margin-top: 5%;
        margin-bottom: 1%;
        justify-content: center;
    }
    .second_star {
        width: 25px;
        height: 25px;
        margin-left: 0%;
    }
    .third_star {
        width: 25px;
        height: 25px;
        margin-left: 5%;
    }

}

@media(min-width:281px) and (max-width:767px) {
    .modal-content {
        width: 100% !important;
    }
    .close_icon {
        right: 1%;
        top: 1%;
        font-size: 25px;
    }
    .main_blur_section {
        height: 75%;
        width: 100%;
        top: 25%;
    }
    .main_content_section {
        height: 90%;
        width: 100%;
        margin: 3% auto;
        flex-direction: column;
    } 

    .first_section {
        width: 45%;
        margin-left: 27.5%;
    }

    .second_section {
        width: 100%;
    }
    .star_section {
        margin-top: 3%;
        justify-content: center;
    }
    .title_section h1 {
        color: #E53865;
        margin: 0%;
        font-size: 22px;
        font-family: 'Poppins-Regular';
        font-weight: 600;
        width: 95%;
    }

    .heart_icon {
        height: 26px;
        width: 26px;
        color: white;
    }

    .location_section img {
        height: 21px;
    }

    .location_section h5 {
        font-size: 17px;
        color: white;
        font-family: 'Poppins-Regular';
        font-weight: 600;
    }

    .introduction_section h4 {
        font-size: 14px;
        font-family: 'Poppins-Regular' !important;
        font-weight: 700 !important;
        color: #000000;
        margin-bottom: 0%;
    }

    .introduction_section p {
        font-size: 10px;
        font-family: 'Poppins-Regular' !important;
        font-weight: 400;
        color: #383838 !important;
    }

    .intersts_section h4 {
        font-size: 14px;
        font-family: 'Poppins-Regular' !important;
        font-weight: 700 !important;
        color: #000000;
        margin-bottom: 0%;
    }


    .music_section {
        border-radius: 3px;
        padding: 1px 4px 3px 4px;
        background-color: rgba(229, 56, 101, 0.5);
    }

    .music_name {
        font-size: 12px;
        font-family: 'DMSans-Regular' !important;
        font-weight: 500 !important;
        color: #000000;
    }

    .sport_section {
        border-radius: 3px;
        padding: 1px 4px 3px 4px;
        background-color: rgba(229, 56, 101, 0.5);
        margin-left: 2%;
    }

    .sport_name {
        font-size: 12px;
        font-family: 'DMSans-Regular' !important;
        font-weight: 500 !important;
        color: #000000;
    }

    .first_star {
        width: 25px;
        height: 25px;
        border: 1.25px solid rgba(255, 255, 255, 0.8);
        border-radius: 100px;
        background-color: rgba(255, 255, 255, 0.3);
        /* background-color: transparent; */
        margin-left: 0%;
    }

    .second_star {
        width: 25px;
        height: 25px;
        border: 1.25px solid rgba(255, 255, 255, 0.8);
        border-radius: 100px;
        background-color: rgba(255, 255, 255, 0.3);
        margin-left: 0%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .third_star {
        width: 25px;
        height: 25px;
        border: 1.25px solid rgba(255, 255, 255, 0.8);
        border-radius: 100px;
        background-color: rgba(255, 255, 255, 0.3);
        /* background-color: transparent; */
        margin-left: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media(min-width:768px) and (max-width:1023px) {
    .main_blur_section {
        height: 75%;
        width: 100%;
        top: 25%;
    }
    .main_content_section {
        height: 90%;
        width: 100%;
        margin: 3% auto;
        flex-direction: column;
    }
    .second_section {
        width: 100%;
    }
    .first_section {
        width: 45%;
        margin-left: 27.5%;
    }
    .introduction_section h4 {
        font-size: 17px;
    }
    .intersts_section h4 {
        font-size: 17px;
    }
    .star_section {
        justify-content: center;
    }
    .second_star {
        margin-left: 0%;
    }
    .third_star {
        margin-left: 2%;
    }
    .introduction_section p {
        font-size: 13px;
    }
}

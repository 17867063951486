.Confirmed_Payment_main_section {
    /* background-color: white; */
    position: relative;
    z-index: 9;
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
    overflow-y: scroll;
}

.Confirmed_Payment_main_section::-webkit-scrollbar {
    display: none;
}

.card_main_section {
    z-index: 9;
    margin-top: 3%;
}

.round_main_section {
    background-color: rgba(59, 177, 67, 0.3);
    border: 1px solid #3BB143;
    height: 130px;
    width: 130px;
    z-index: 999;
    border-radius: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.round_inner_scetion {
    height: 70%;
    width: 70%;
    z-index: 999;
    border-radius: 100px;
    background-color: #3BB143;
    display: flex;
    justify-content: center;
    align-items: center;
}

.round_inner_scetion img {
    width: 50%;
}

.Payment_detailes_card {
    /* box-shadow: rgba(0, 0, 0) 0px 10px 17px -14px; */
    /* box-shadow: 0px 10px 17px -14px gray; */
    border-radius: 5px;
    width: 100%;
    /* box-shadow: 0px 8px 8px -7px gray;
    border-bottom: 1px solid rgb(232, 224, 224);
    border-right: 1px solid rgb(232, 224, 224);
    border-left: 1px solid rgb(232, 224, 224); */
    margin-top: -18%;
    padding-top: 5%;
    box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.3);
    background-color: white;
}

.card_line {
    height: 1px;
    width: 100%;
}

.Great_title {
    font-size: 22px;
    color: #EF3672;
}

.confirmed_payment_information {
    font-size: 22px;
}

.started_switching {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
}

.your_summary {
    font-size: 16px;
}

.total_paid {
    font-size: 17px;
}

.price_dollar {
    font-size: 27px;
    color: #EF3672;
}

.expiry_date {
    font-size: 19px;
    color: #EF3672;

}

.using_card_detailes {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
}

.explore_hukup_btn{
    border:none !important;
    color: #EF3672 !important;
    background-color: white !important;
    border-radius: 5px !important;
    font-size: 18px;
    padding: 5px 10px !important;
}

@media(min-width:280px) and (max-width:767px) {
    .Confirmed_Payment_main_section {
        height: calc(80dvh - var(--banner-div-height));
    }

    .round_main_section {
        height: 110px;
        width: 110px;
    }

    .card_main_section {
        z-index: 9;
        margin-top: 10%;
    }

    .Payment_Confirmation {
        font-size: 29px;
    }

    .Great_title {
        font-size: 21px;
    }

    .started_switching {
        font-size: 13px;
    }

    .your_summary {
        font-size: 15px;
    }

    .total_paid {
        font-size: 15px;
    }

    .price_dollar {
        font-size: 26px;
    }

    .expiry_date {
        font-size: 18px;
    }

    .using_card_detailes {
        font-size: 17px;
    }
    .explore_hukup_btn {
        font-size: 15px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .card_main_section {
        z-index: 9;
        margin-top: 10%;
    }

}


@media(min-width:1024px) and (max-width:1299px) {
    .round_main_section {
        height: 110px;
        width: 110px;
    }

    .card_line {
        height: 1px;
        width: 100%;
    }
}

@media(min-width:1300px) and (max-width:1440px) {}
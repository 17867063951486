.new_message_page {
    position: relative;
}

.new_add_class .chat_u {
    bottom: 20px;
    position: absolute;
    cursor: pointer;
}

.chat_card {
    background: #FFFFFF66;
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
}
.Defaultmessage{
    background: #f4b8c8;
    color: #E53865;
    padding: 4px 10px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    border-radius: 10px;
}
.profile_img {
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
}

.moblie_card {
    cursor: pointer;
}

.moblie_card_true {
    filter: blur(6px);
}

.all_user_chat:hover {
    border: 1px solid #E53865 !important;
}

.all_user_chat_true {
    border: 1px solid #E53865 !important;
}

.imgset_header {
    width: 46px;
    height: 46px;
    border-radius: 50px;
}

.imgset {
    border-radius: 50%;
    width: 38px !important;
    height: 38px !important;

}

.user_name {
    color: #E53865;
    font-weight: 600;
    font-size: 20px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user_info {
    color: #00000080;
    font-size: 16px;
    font-weight: 500;

}

.match_select_menu {
    box-shadow: 0px 20px 40px 0px #3B405626 !important;
    border: 1px solid white !important;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 13px !important;
    color: #00000099 !important;
    padding: 6px 26px 6px 8px !important;
    background: url("../../../images/Vector\ \(Stroke\).png") 95% / 11% no-repeat;
    background-size: 16px;
}

.mes_filter_fake {
    position: absolute;
    top: 0;
    height: 38px;
    width: 100%;
    cursor: pointer;
}

.icon-feedback {
    position: absolute;
    z-index: 11;
    display: block;
    width: 2.375rem;
    height: 2.2rem;
    line-height: 2.2rem;
    text-align: center;
    pointer-events: none;
    color: #00000080;
    font-size: 22px;
}

.search_user {
    padding: 0.375rem 0.75rem 0.375rem 2rem !important;
    box-shadow: 0px 20px 40px 0px #3B405626 !important;
    border: 1px solid white !important;
    color: #00000080 !important;
    border-radius: 15px !important;
    height: 2.2rem;
}

.search_user_non_premum {
    background-color: rgba(210, 210, 210, 0.7) !important;
    caret-color: transparent;
    cursor: pointer;
}

.user_chat_profile_img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    min-width: 38px;
}

.user_chat_profile_img_chat_side {
    width: 100%;
    cursor: pointer;
}

.profile_img_col {
    align-items: center;
    display: flex;
    justify-content: center;
}

.chat_user_name {
    font-weight: 700;
    font-size: 14px;
    font-family: Poppins-Bold;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.chat_user_msg {
    font-weight: 400;
    font-size: 14px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.all_user_chat {
    padding: 4px 8px;
    border-radius: 14px !important;
    box-shadow: 0px 3px 4px 0px #3B405626;
    border: 1px solid white;
    margin: 4px 0px;
}

.chat_number {
    float: right;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF0055CC;
    border-radius: 50%;
    height: auto;
    color: white;
    font-size: 14px;
}

.user_details {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.chat_number_col {
    align-items: end;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
}

.chat_time {
    color: #FF0055CC;
    font-size: 14px;
}

.chat_user_card {
    padding: 0px;
    max-height: calc(100vh - (20vh + 145px));
    max-height: calc(100dvh - (20dvh + 145px) - var(--banner-div-height));
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
}

.user_top_row {
    box-shadow: 0px 20px 40px 0px #3B405626;
    padding: 5px 7px;
    border-radius: 10px;
}

.top_date_button {
    box-shadow: 0px 10px 20px 0px #0000001A;
    color: white;
    background-color: #E53865;
    border: none;
    border-radius: 24px;
    padding: 8px 7px;
    font-size: 14px;

}

.open_close_col {
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
}

.profile_user {
    font-size: 20px;
    font-weight: 700;
    font-family: Poppins-Bold;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user_profile_status {
    font-size: 14px;
    font-weight: 400;
    color: #00000080;
}

.top_option {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.status_dot_online {
    color: #3BB143;
    margin-left: -3px
}

.status_dot_offline {
    color: #E53865;
    margin-left: -3px
}

.chat_user {
    display: flex;
}

/* .incomming_msg p {
    background-color: #E4E0DD;
    border-radius: 10px 10px 10px 0px;
    padding: 10px 15px;
    word-break: break-all;
    margin: 0;
} */

.incomming_msg p {
    background-color: #e4e0dd;
    border-radius: 10px 10px 10px 0;
    margin: 0;
    padding: 10px 15px;
    word-break: break-word;
}

.incomming_msg {
    display: flex;
    justify-content: start;
}

.downloadBtn {
    cursor: pointer;
    color: #000 !important;
}

.msg_time {
    color: #00000059;
    font-size: 15px;
}

.user_side_chat {
    justify-content: end;
    flex-direction: row-reverse;
}

.outgoing_msg {
    display: flex;
    justify-content: end;
}

/* .outgoing_msg p {
    border-radius: 15px 15px 0px 15px;
    padding: 10px 15px;
    background-color: #f8fff8;
    word-break: break-all;
    margin: 0;
} */
.outgoing_msg p {
    background-color: #f8fff8;
    border-radius: 15px 15px 0 15px;
    margin: 0;
    padding: 10px 15px;
    word-break: break-word;
}


.msg_check {
    color: #E53865;
}

.doc_name {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 4px;
}

.msg_un_check {
    color: #000;
}

.user_msg_time {
    color: #00000059;
    font-size: 10px;
    display: flex;
    margin-top: 2.5px;
}

.user_side_chat .user_msg_time {
    justify-content: end;
}

.notification {
    border-radius: 0px 10px 0px 10px;
    padding: 4px 10px;
    /* width: max-content; */
    background-color: white;
    text-align: center;
}

.all_user_chat_section {
    max-height: calc(100vh - (20vh + 138px));
    max-height: calc(100dvh - (20dvh + 138px) - var(--banner-div-height));
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: calc(100vh - (20vh + 138px));
    min-height: calc(100dvh - (20dvh + 138px) - var(--banner-div-height));
    /* padding-bottom: 7px; */
}

.your_msg {
    border: 1px solid #E5386580 !important;
    border-radius: 20px !important;
    height: 2.75rem !important;
    background: rgba(0, 0, 0, 0.4) !important;
}

.msg_box {
    border: 1px solid #E5386580 !important;
    border-radius: 20px !important;
    background-color: White !important;
    height: 2.75rem !important;
    box-shadow: none !important;
    padding: 0.375rem 2.75rem 0.375rem 2.5rem !important;
}

.msg_box_disble {
    border: 1px solid #E5386580 !important;
    border-radius: 20px !important;
    background-color: #e9ecef !important;
    opacity: 1 !important;
    height: 2.75rem !important;
    box-shadow: none !important;
    padding: 0.375rem 2.75rem 0.375rem 2.5rem !important;
    caret-color: transparent;
}

.send_msg_img {
    background-color: transparent;
    border: none;
}

.google_map_maindiv {
    height: 200px;
    width: 100%;
}

.marker_point {
    height: 30px;
}

.send_msg_button {
    padding: 12px;
    background: #E53865;
    border-radius: 13px;
    width: 41px;
    cursor: pointer;
}

.send_sticker {
    color: #00000066;
    position: absolute;
    z-index: 11;
    display: block;
    /* width: 12.375rem; */
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    /* pointer-events: none; */
    font-size: 30px;
    left: calc(100% - 111px)
}

.message_user {
    padding: 5px 20px;
}

.chat_message_image {
    height: 150px;
    width: auto;
}

.chat_message_location {
    text-align: -webkit-right;
    background-color: #E4E0DD;
    padding: 10px;
    border-radius: 10px 10px 10px 0px;
}

.user_side_chat .chat_message_location {
    background-color: #EBEFEB;
    border-radius: 15px 15px 0px 15px;
}

.user_side_chat .chat_message_image {
    text-align: end;
}

.user_side_chat .chat_message_image image {
    background-color: #EBEFEB;
    border-radius: 15px 15px 0px 15px;
}

.chat_message_image img {
    height: 150px;
    max-width: 200px;
    object-fit: contain;
    background-color: #E4E0DD;
    padding: 5px;
    border-radius: 10px 10px 10px 0px;
    cursor: pointer;
}

.chat_message_image video {
    height: 150px;
    max-width: 200px;
    object-fit: cover;
    background-color: #E4E0DD;
    padding: 5px;
    border-radius: 10px 10px 10px 0px;
}

.user_side_chat .chat_message_image video {
    background-color: #EBEFEB;
    border-radius: 15px 15px 0px 15px;
}

.media_sticker {
    cursor: pointer;
    height: 45px;
    width: 30px;
    color: rgba(0, 0, 0, 0.7);
    /* color: #00000066; */
}

.your_msg:focus {
    box-shadow: none !important;
}

.back_button {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Report_blocked_add_dote .dropdown-toggle::after {
    content: none !important;
    display: none !important;
}

.Report_blocked_add_dote .dropdown-toggle {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.currentRoom_Data_name {
    text-decoration: none !important;
    color: #000 !important;
    cursor: pointer;
}

.dates_text {
    padding: 2px 14px;
    border-radius: 15px;
    background-color: white;
    text-align: center;
}

.dateIcon {
    border-radius: 50%;
    background: #E53865;
    color: white;
    padding: 3px;
    font-size: 20px;
    cursor: pointer;
}

.date_section {
    position: sticky;
    top: 0;
    z-index: 9;
}

.date_section_gallery {
    position: sticky;
    top: 45px;
    z-index: 9;
}

.emoji_button {
    position: absolute;
    z-index: 11;
    display: block;
    width: 3rem;
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    color: #00000080;
    font-size: 22px;
}

.emojiIcon {
    cursor: pointer;
}

.emoji-box {
    height: 47% !important;
    width: 20% !important;
    position: fixed !important;
    bottom: 118px !important;
}

.mess_all_notifi_time {
    font-size: 10px;
    color: #00000059;
}

.mess_apr_View_link {
    color: #E53865 !important;
}

.pre_Message_fir_section {
    width: 80%;
}

.pre_Message_seco_section {
    width: 20%;
}

.prefocues {
    /* background-color: var(--bs-body-bg); */
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25) !important;
}

.hed_dis_chat_count {
    color: #E53865;
}

.message_encrypted {
    filter: blur(10px);
    pointer-events: none !important;
}

.ril__caption,
.ril__toolbar {
    background-color: transparent !important;
}

.off_on_premium {
    cursor: pointer;
    color: #EF3672;

}

.message_private_gallery_notifiction {
    max-width: 90%;
    margin: auto;
    width: max-content;
}

.message_all_notifiction {
    max-width: max-content;
    width: 90%;
    margin: auto;
}

.dismess_permi_locatione {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.Message_quota_upgrade {
    width: 50%;
    border: 1px solid #E53865 !important;
    background-color: #E53865 !important;
    border-radius: 50px !important;
}

.Message_quota_upgrade:focus {
    box-shadow: none !important;
    border: 1px solid #E53865 !important;
}

.Message_quota_btn_Cancel {
    width: 50% !important;
    border: 1px solid #E53865 !important;
    background-color: transparent !important;
    border-radius: 50px !important;
    color: #E53865 !important;
}

.Message_quota_btn_Cancel:focus {
    box-shadow: none !important;
    border: 1px solid #E53865 !important;
}

.offLocation_upgrade_line {
    color: #EF3672;
    cursor: pointer;
}

.allow_locatione_close {
    font-size: 25px;
}
.upload_chat_gallery_access{
    color: white;
    background-color: #EF3672;
    border-radius: 24px;
    border: none;
    padding: 8px;
}

.upload_chat_gallery_access_disabled{
    color: white;
    background-color: rgb(184 180 180);
    border-radius: 24px;
    border: none;
    padding: 8px;
}


.upload_chat_gallery_access_Cancel{
    border: 1px solid #E53865;
    background-color: transparent;
    border-radius: 24px;
    color: #E53865;
    padding: 8px;
}


@media screen and (max-width: 280px) {
    .hed_dis_chat_count {
        font-size: 8px;
    }

    .currentRoom_Data_name {
        font-size: 12px;
    }

    .user_profile_status {
        font-size: 9px;
    }

    .chat_location_icon {
        width: 12px;
    }

    .user_profile_status {
        font-size: 9px !important;
    }

    .profile_user {
        width: auto;
        max-width: 39%;
    }

    .user_chat_profile_img_chat_side {
        width: 100% !important;
    }

    .imgset_header {
        height: 41px;
    }

    .imgset {
        border-radius: 50%;
        width: 25px !important;
        height: 25px !important;
    }

    .new_add_class .chat_u {
        bottom: 15px !important;
        right: 6px !important;
    }

    .outgoing_msg p {
        font-size: 10px;
        padding: 8px 15px;
        word-break: break-word;
    }

    .incomming_msg p {
        font-size: 10px;
        padding: 8px 15px;
        word-break: break-word;
    }

    .user_msg_time {
        font-size: 7px;
    }

    .mess_all_notifi_time {
        font-size: 7px;
    }

    .message_all_notifiction {
        max-width: max-content;
        width: 90%;
        margin: auto;
        font-size: 11px;
    }

    .dates_text {
        font-size: 11px !important;
    }

    .dateIcon {
        font-size: 14px;
    }

    .notification {
        font-size: 11px !important;
    }

    .main_profile_imgcol_padding {
        width: 45px !important;
    }

    .chat_user_name {
        font-size: 12px !important;
    }

    .chat_user_msg {
        font-size: 11px !important;
    }

    .chat_time {
        font-size: 10px !important;
    }

    .chat_number {
        min-width: 15px !important;
        font-size: 10px !important;
    }

    .audio-container {
        width: 210px;
        max-width: 210px;
        height: 40px;
    }
}

@media screen and (max-width: 768px) {
    .user_name {
        font-size: 16px;
    }

    .user_info {
        font-size: 16px;
    }

    .moblie_card {
        padding: 0px;
    }

    .profile_user {
        width: auto;
        max-width: 40%;
        font-size: 14px !important;
    }

    .user_profile_status {
        font-size: 11px;
    }

    .top_date_button {
        font-size: 10px;
    }

    /* .message_private_gallery_notifiction{
        max-width: 90%;
        margin: auto;
    } */
    .hed_dis_chat_count {
        color: #E53865;
        font-size: 11px !important;
    }

    .notification {
        font-size: 12px;
    }

    .outgoing_msg p {
        font-size: 12px !important;
    }

    .incomming_msg p {
        font-size: 12px !important;
    }

    .new_add_class .chat_u {
        bottom: 15px;
    }

    .user_msg_time {
        font-size: 9px !important;
    }

    .mess_all_notifi_time {
        font-size: 9px;
    }

    .imgset {
        border-radius: 50%;
        width: 30px !important;
        height: 30px !important;
    }

    .dates_text {
        font-size: 13px;
    }

    .dateIcon {
        font-size: 16px;
        cursor: pointer;
    }

    .chat_location_icon {
        width: 12px;
    }

    .user_chat_profile_img_chat_side {
        width: 46px !important;
    }

    /* .audio-container {
        width: 210px ;
        max-width: 210px ;
        height: 40px;
      } */
}

@media(min-width:715px) and (max-width:717px) {
    .imgset {
        width: 34px !important;
        height: 34px !important;
    }

    .back_button {
        justify-content: flex-end;
    }

    .user_chat_profile_img_chat_side {
        width: 95% !important;
    }

    .imgset_header {
        height: 40px;
    }
}

@media screen and (max-width: 1024px) {
    .moblie_card {
        padding: 0px;
    }
}

@media only screen and (max-width: 1439px) and (min-width: 300px) {
    .new_add_class {
        padding: 0.50rem !important;
    }
}

@media only screen and (max-width: 4000px) and (min-width: 1300px) {

    /* .user_chat_profile_img{
        width: 100% ;
    } */
    .user_chat_profile_img_chat_side {
        width: 100%;
    }

    .new_add_class {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1299px) and (min-width: 300px) {
    .new_add_class {
        display: flex;
        justify-content: center;
    }

    .outgoing_msg p {
        font-size: 14px;
    }

    .incomming_msg p {
        font-size: 14px;
    }

    .user_msg_time {
        font-size: 10px;
    }

    .msg_time {
        font-size: 14px;
    }
}

/* @media only screen and (max-width: 1439px) and (min-width: 426px){
    .set_image_main_pic{
      padding: 0.25rem !important;
    }
  } */

@media only screen and (max-width: 321px) and (min-width: 280px) {

    /* .set_image_main_pic{
      padding: 0.25rem !important;
    } */
    .user_chat_profile_img_chat_side {
        width: 91%;
    }

    .chat_u {
        width: 80%;
    }
}

/* @media only screen and (max-width: 426px) and (min-width: 322px){
    .set_image_main_pic{
      padding: 0.50rem !important;
    }
  } */

@media only screen and (max-width: 424px) and (min-width: 300px) {
    .main_profile_imgcol_padding {
        padding-left: 12px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
    .main_profile_imgcol_padding {
        padding-left: 12px;
    }
}

/* @media only screen and (max-width: 992px) and (min-width: 426px) {
    .user_chat_profile_img {
        width: 70%;
    }
} */


@media screen and (max-width: 992px) {
    .open_close_col {
        display: none;
    }

    .not_show_button {
        display: none;
    }

    .moblie_screen_chat_header {
        padding: 0px !important;
    }
}

@media screen and (min-width: 992px) {
    .show_menu {
        display: none !important;
    }
}

.date_model_header {
    font-size: 24px;
    font-weight: 400;
}

.going_date_model {
    background-color: #FFFFFF;
    box-shadow: 0px 20px 40px 0px #3B405626;
    border-radius: 48px;
    text-align: center;
    padding: 20px 26px;
}

.ok_model_button {
    color: white;
    background-color: #E53865;
    border: 1px solid #E53865 !important;
    border-radius: 24px;
    padding: 8px 90px;
    font-weight: 700;
}

.cancel_model_button {
    background: white;
    color: #E53865;
    border: 1px solid #E53865;
    border-radius: 24px;
    padding: 8px 71px;
    font-weight: 700;
}

.model_text {
    text-align: center;
    padding: 20px 0px;
    color: #000000B2;
}

.speed-dial {
    position: absolute;
    right: 20px;
    bottom: 20px;
    transform: scale(0);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.speed-dial.open {
    transform: scale(1);
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
    .chat_user_card {
        max-height: calc(100dvh - (18dvh + 135px) - var(--banner-div-height));
    }

    .chat_card {
        height: calc(100dvh - 18dvh - var(--banner-div-height));
    }

    .open_close_col {
        /* height: calc(100dvh - 30dvh - var(--banner-div-height)); */
        /* height: calc(100dvh - 18dvh - var(--banner-div-height)); */
        height: calc(100dvh - 9dvh - var(--banner-div-height));
        /* border: 1px solid blue; */
    }

    .all_user_chat_section {
        /* min-height: calc(100dvh - (18dvh + 130px) - var(--banner-div-height)); */
        /* max-height: calc(100dvh - (18dvh + 130px) - var(--banner-div-height)); */

        min-height: calc(100dvh - (9dvh + 130px) - var(--banner-div-height));
        max-height: calc(100dvh - (9dvh + 130px) - var(--banner-div-height));
        /* border: 1px solid red; */

    }

    aside.EmojiPickerReact.epr-main {
        height: 300px !important;
    }

    .EmojiPickerReact .epr-header .epr-header-overlay {
        padding: 5px 5px !important;
    }

    aside.EmojiPickerReact.epr-main:has(input:placeholder-shown) .epr-category-nav {
        padding: 5px 5px !important;

    }

}

@media(min-width:768px) and (max-width:1023px) {
    .chat_card {
        height: calc(100dvh - 18dvh - var(--banner-div-height));
    }

    .chat_user_card {
        max-height: calc(100dvh - (18dvh + 135px) - var(--banner-div-height));
    }

    .open_close_col {
        /* height: calc(100dvh - 30dvh - var(--banner-div-height)); */
        /* height: calc(100dvh - 20dvh - var(--banner-div-height)); */
        height: calc(100dvh - 9dvh - var(--banner-div-height));
    }

    .all_user_chat_section {
        /* max-height: calc(100dvh - (18dvh + 125px) - var(--banner-div-height));
        min-height: calc(100dvh - (18dvh + 125px) - var(--banner-div-height)); */
        max-height: calc(100dvh - (10dvh + 125px) - var(--banner-div-height));
        min-height: calc(100dvh - (10dvh + 125px) - var(--banner-div-height));
    }
}

@media only screen and (min-width: 280px) and (max-width: 320px) {
    /* .user_chat_profile_img {
        width: 84%;
    } */
}

@media only screen and (min-width: 321px) and (max-width: 376px) {
    /* .user_chat_profile_img {
        width: 75%;
    } */

    .user_chat_profile_img_chat_side {
        width: 84%;
    }

    .chat_u {
        width: 80%;
    }

    .set_back_click {
        width: 5% !important;
    }
}

@media only screen and (min-width: 376px) and (max-width: 426px) {
    /* .user_chat_profile_img {
        width: 64%;
    } */

    .user_chat_profile_img_chat_side {
        width: 75%;
    }

    .chat_u {
        width: 66%;
    }

    .set_back_click {
        width: 4% !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 999px) {
    .user_chat_profile_img_chat_side {
        width: 80%;
    }

    .chat_u {
        width: 66%;
    }

    .set_back_click {
        width: 2% !important;
    }
}

@media only screen and (min-width: 999px) and (max-width: 1439px) {
    .chat_u {
        width: 70%;
    }
}

@media(min-width:374px) {
    .model_width_set {
        width: 320px !important;
        margin: auto;
    }
}

.new_position {
    position: absolute;
    bottom: 100%;
}

.add_main_div_emoji {
    position: relative;
}

.epr-preview {
    display: none !important;
}

.preMessageBox {
    text-align: start;
    height: 16rem;
    overflow: scroll;
}

.preMessageBox-event-details{
    text-align: start;
    height: 22rem;
    overflow: scroll;
}

.preMessageBox-event-details p {
    cursor: pointer;
}
.preMessageBox p {
    cursor: pointer;
}

.preMessage {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.PriMsgEditDltBtn {
    /* margin: 0px 10px; */
    font-size: 25px;
}

.stickerImg {
    height: 100px;
    width: 100px;
    padding: 7px;
    object-fit: contain;
    cursor: pointer;
}

.stickerBox {
    height: 22rem;
    margin-bottom: 12px;
    overflow: scroll;
}

.google_map_maindiv div {
    cursor: pointer !important
}
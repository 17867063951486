.DeleteAccModal_modal_main_section{
    background-color: #FFFFFF;
    width: 80%;
    margin: auto;
    padding: 25px;
    border-radius: 48px;
    overflow: hidden;
}
.DeleteAccModal_broken_heart_icon{
    width: 85px;
    height: 85px;
}
.DeleteAccModal_Are_YouSure{
    font-weight: 500;
    color: #E53865;
    font-size: 22px;
}
.DeleteAccModal_first_para{
    font-weight: 400;
    font-size: 17px;
    color: black;
}
.DeleteAccModal_second_para{
    font-weight: 400;
    font-size: 15px;
    color: black;
}
.DeleteAccModal_Delete_btn{
    width: 80%;
    border: 1px solid #E53865 !important;
    background-color: #E53865 !important;
    border-radius: 50px !important;
}
.DeleteAccModal_Cancel_btn{
    width: 80%;
    border: 1px solid #E53865 !important;
    background-color: transparent !important;
    border-radius: 50px !important;
    color: #E53865 !important;
}
.DeleteAccModal_Delete_btn:focus{
    box-shadow: none !important;
    border: 1px solid #E53865 !important;

}
.DeleteAccModal_Cancel_btn:focus{
    box-shadow: none !important;
    border: 1px solid #E53865 !important;
}

.DeleteAccModal_input_section{
    width: 65%;

}
.form-group {
    position: relative;
    margin-bottom: 20px;
  }
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 13px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  .form-group label {
    position: absolute;
    left: 13px;
    top: 12px;
    font-size: 14px;
    color: #777;
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  }
  .form-group input:focus {
    border-color: #E8E6EA;
    outline: none;
  }
  
  .form-group input:focus + label,
  .form-group input:not(:placeholder-shown) + label {
    top: -6px;
    font-size: 12px;
    color:  rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
    padding: 0 4px;
    border-radius: 13px;
    left: 13px;
  }

@media(min-width:280px) and (max-width:767px) {
    .DeleteAccModal_broken_heart_icon{
        width: 60px;
        height: 60px;
    }
    .DeleteAccModal_Are_YouSure{
        font-size: 19px;
    }
    .DeleteAccModal_first_para{
        font-size: 14px;
    }
    .DeleteAccModal_second_para{
        font-size: 12px;
    }
    .DeleteAccModal_Delete_btn{
        width: 70%;
        font-size: 14px !important;
    }
    .DeleteAccModal_Cancel_btn{
        width: 70%;
        font-size: 14px !important;
    }
    .form-group input {
        padding: 5px 10px;
    }
    .form-group label {
        top: 7px;
        font-size: 12px;
    }
}

@media(min-width:768px) and (max-width:1023px) {

}

.DeleteAccModal_modal_main_section .ReactInputVerificationCode__container{
    width: 100% !important;
    column-gap: 4.5%;
}

.DeleteAccModal_modal_main_section .ReactInputVerificationCode__item{
    width: 20% !important;
    line-height: 2.2 !important;
    height: 100% !important;
}

.set_otp_name_center{
    cursor: pointer;
    font-weight: 500;
    /* box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3) !important; */
    border: none;
    background-color: transparent !important;
}

.file_upload_input{
    padding: 15px;
}
.set_input_upload{
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.set_main_img_cropmodel{
    height: 300px;
    width: auto;
}
.user_cropimage_model{
    border-radius: 40px ;
    background-color: white;
}
.set_width_img{
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.crop_button{
    box-shadow: 0px 10px 20px 0px #0000001A;
     color: white;
     background-color: #E53865;
     border: none;
     border-radius: 24px;
     padding: 7px 20px;
}

.nav-tabs .nav-link.active {
    color: black !important;
    background-color: transparent !important;
    border-bottom: 2px solid #EF3672 !important;
    box-shadow: none !important;
    padding: 5px 0px !important;
    font-weight: 600;
    font-size: 30px;
}

.nav-tabs .nav-link {
    color: black !important;
    background-color: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
    border-top: 0px solid !important;
    border-right: 0px solid !important;
    border-left: 0px solid !important;
    padding: 5px 0px !important;
    font-weight: 600;
    font-size: 30px;
    width: 100% !important;
    letter-spacing: 1px;
}

.nav-tabs {
    border-bottom: none !important;

}

.nav-tabs .nav-item {
    width: 50% !important;
}

.tab-content {
    padding-left: 0% !important;
    padding-right: 0% !important;
}

.linkover {
    color: white !important;
    text-decoration: none !important;
}

.Liked_section_contain {
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
}

.pinkcustom {
    color: #EF3672;
}

.Liked_section_slider {
    height: 72vh;
    height: calc(72dvh - var(--banner-div-height));
    overflow-y: scroll;
}

.Liked_section_slider.event {
    /* height: calc(65dvh - var(--banner-div-height)); */
    height: calc(83dvh - var(--banner-div-height));
}

.card_section_main_sidebar_liked {
    /* border: 1px solid red; */
    position: absolute;
    /* top: 0; */
    top: calc(50% - 56.5px);
    right: 0;
    /* height: 100%; */
    display: flex;
    align-items: center;
    z-index: 9;
}

.setuserencashmentclass .nav-tabs .nav-item {
    width: 33.333% !important;
}

.settabfontsize{
    font-size: 30px;
}

@media screen and (max-width: 280px) {
    .nav-tabs .nav-link {
        font-size: 13px !important;
    }

    .nav-tabs .nav-link.active {
        font-size: 13px !important;
    }
    .settabfontsize{
        font-size: 9px !important;
    }
}

@media(min-width:280px) and (max-width:767px) {
    .nav-tabs .nav-link {
        font-size: 16px;
    }

    .nav-tabs .nav-link.active {
        font-size: 16px;
    }
    .settabfontsize{
        font-size: 9px !important;
    }

    .Liked_section_contain {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .Liked_section_slider {
        /* height: calc(65dvh - var(--banner-div-height)); */
        height: calc(74dvh - var(--banner-div-height));
    }

    .Liked_section_slider.event {
        /* height: calc(65dvh - var(--banner-div-height)); */
        height: calc(85dvh - var(--banner-div-height));
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .settabfontsize{
        font-size: 15px !important;
    }
    .Liked_section_contain {
        height: calc(82dvh - var(--banner-div-height));
    }

    .Liked_section_slider {
        /* height: calc(65dvh - var(--banner-div-height)); */
        height: calc(74dvh - var(--banner-div-height));
    }

    .Liked_section_slider.event {
        /* height: calc(65dvh - var(--banner-div-height)); */
        height: calc(85dvh - var(--banner-div-height));
    }
}
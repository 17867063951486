.cameraicon_set {
    width: 10%;
}

.click_icon_set_pic {
    width: 84%;
    position: absolute;
    bottom: 10%;
    display: flex;
    justify-content: flex-end;
}

.user_edit_details {
    width: 20px;
    cursor: pointer;
}
.editIcon-Profile{
    position: absolute;
    right: 0;
    background: #E53865;
    color: white;
    border-radius: 50px;
    padding: 10px;
    width: 35px;
    height:35px;
    display: flex;
    align-items: center;
}
.user_verify-icon {
    width: 35px;
    height: 35px;
}

.user_verify-icon-home {
    width: 26px;
    height: 26px;
}

.user_verify-icon-home-model {
    width: 35px;
    height: 35px;

}
.add_new_center_imginfo {
    display: flex;
    align-items: center;
}

.verify_email {
    box-shadow: 0px 10px 20px 0px #0000001A;
    color: white;
    background-color: #E53865;
    border: none;
    border-radius: 24px;
    padding: 9px 14px;
    font-size: 15px;
}

.cancel_btn {
    box-shadow: 0px 10px 20px 0px #0000001A;
    color: #000000;
    background-color: #FFFFFF;
    border: none;
    border-radius: 24px;
    padding: 9px 14px;
    font-size: 15px;
}

.main_modal_sectione .modal-content {
    width: 80% !important;
    margin: auto !important;
}
.modal_main_section_add_num_email{
    border-radius: 9px;
    background-color: white;
}
.modal_main_section_add_num_email .modal-body {
    padding:20px 25px !important;
}

.email_num_title {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
}

.close_email_number{
    cursor: pointer;
    font-size: 25px;
}
.email_verify_main{
    margin: auto;
}
.email_verify_main .form-control{
    border: 2px solid #E53865 !important;
    box-shadow: none !important;
    background-color: transparent !important;
}
.email_verify_main .form-control:focus{
    border: 2px solid #E53865 !important;
    box-shadow: none !important;
    background-color: transparent !important; 
}
.css-1jqq78o-placeholder{
    margin-left:5px !important;
}
.event-tags{
    border: 2px solid #E53865 !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.event-tags:focus{
    border: 2px solid #E53865 !important;
    box-shadow: none !important;
    background-color: transparent !important; 
}
.For_account_recovery{
    font-size: 14px;
}
.Num_verify_main{
    border: 2px solid #E53865;
    border-radius: 5px;
    margin: auto;
}
.Num_verify_main .form-select{
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    background-position: right 0px center;
    width: 100px !important;
    padding: .375rem 17px .375rem 5px !important;
}
.Num_verify_main .form-select:focus{
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.Num_verify_main .form-control{
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    background-position: right 6px center;
}
.Num_verify_main .form-control:focus{
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}
.verify_otp_main_section{
    /* border: 1px solid red; */
    /* width: 80%; */
    margin: auto;
}

.verify_otp_main_section .ReactInputVerificationCode__container{
    width: 100% !important;
    column-gap: 4.5%;
}
.verify_otp_main_section .ReactInputVerificationCode__item{
    width: 20% !important;
    line-height: 2.2 !important;
    height: 100% !important;
}
.setqrcodeimgwidth{
    width: 160px;
    height: 160px;
}
#profileinputgroupreferrlcode input{
    background-color: transparent;
    border: 1px solid #E53865;
    border-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
#profileinputgroupreferrlcode input:focus-visible{
    outline: 0 !important;
}
#profileinputgroupreferrlcode input:focus{
    box-shadow: none !important;
}
.copyreferrllink{
    background-color: #E53865 !important;
    border-left: 1px solid #E53865 !important;
    border-top: 1px solid #E53865 !important;
    border-bottom: 1px solid #E53865 !important;
    border-right: 1px solid white !important;
    cursor: pointer;
}
.sharereferrllink{
    background-color: #E53865 !important;
    border-right: 1px solid #E53865 !important;
    border-top: 1px solid #E53865 !important;
    border-bottom: 1px solid #E53865 !important;
    border-left: 1px solid white !important;
    cursor: pointer;
    border-top-right-radius:8px !important;
    border-bottom-right-radius:8px !important;
}
.qrcodeshareoption{
    padding: 6px 11px;
    background: #E53865;
    border-radius: 8px;
    cursor: pointer;
}
@media(max-width:280px) {
    .main_modal_sectione .modal-content {
        width: 100% !important;
        margin: auto !important;
    }
    .modal_main_section_add_num_email .modal-body {
        padding: 15px 15px !important;
    }
    .Num_verify_main .form-select {
        background-position: right 0px center;
        width: 40% !important;
        padding: 0.375rem 14px 0.375rem 5px !important;
        font-size: 14px !important;
    }
    /* .Num_verify_main .form-select:focus{
        background-position: right 0px center;
        width: 40% !important;
        padding: 0.375rem 14px 0.375rem 5px !important;
        font-size: 14px !important;
    } */
}
@media(min-width:281px) and (max-width:767px) {
    .main_modal_sectione .modal-content {
        width: 100% !important;
        margin: auto !important;
    }
    .modal_main_section_add_num_email .modal-body {
        padding: 15px 15px !important;
    }
}

@media(min-width:280px) and (max-width:767px) {
    .verify_email {
        padding: 5px 10px;
        font-size: 12px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .verify_email {
        padding: 6px 12px;
        font-size: 14px;
    }
}

.ql-container {
    min-height: 150px; /* Adjust as needed */
    max-height: 400px; /* Optional: Restrict max height */
    overflow-y: auto;  /* Enable scrolling */
}
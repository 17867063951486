.offcanvas_main {
    background-color: rgba(255, 255, 255, 0.9) !important;
}

.offcanvas_header {
    /* position: relative !important; */
    border: 0px solid red;
    text-align: center;
    padding: 20px;
    /* box-shadow: 0px 0px 2px 8px rgba(0, 0, 0, 0.5); */
    box-shadow: 0px 1px 60px 2px rgba(0, 0, 0, 0.3);

}

.offcanvas_main_selector {
    box-shadow: 0px 20px 40px 0px rgba(59, 64, 86, 0.15) !important;
    width: 50% !important;
    border-radius: 50px !important;
    color: #00000099 !important;
    padding: 6px 32px 6px 7% !important;
    background-position: right 8% center !important;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    font-weight: 500;
    
}



.offcanvas_header .offcanvas_close_icon {
    /* position: absolute; */
    /* right: 7%; */
    /* top: 32%; */
    font-size: 31px;
    color: rgba(0, 0, 0, 0.5) !important;
}

.offcanvas_Relationship_main {
    margin-top: 3%;
}

.offcanvas_checkbox_commoan {
    width: 100%;
}

.offcanvas_checkbox_primeam {
    width: 50%;
}

input[type=checkbox] {
    padding: 2.2% !important;
    border-radius: 0% !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5) !important;
    /* border: 10px solid rgba(0, 0, 0,) !important; */
    border: 1px solid rgb(176, 176, 176) !important;
    /* border: none !important; */
}

input[type=checkbox]:checked {
    background-color: #EF3672 !important;
    box-shadow: none !important;
    border: 1px solid #cf275c !important;
}

.offcanvas_cheakbox_header {
    font-family: 'Poppins-Regular' !important;
    font-weight: 600;
    color: #000000;
    font-size: 18px;
}

.form-check-label {
    font-family: 'Poppins-Regular' !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    cursor: pointer;
}

.offcanvas_filter_name {
    color: #EF3672;
}

.offcanvas_cheakbox_mainsection {
    /* margin-top: 3%; */
}

.iconintrest {
    width: 20px;
    height: 20px;
}

.offcanvas_filter_selector {
    box-shadow: 0px 20px 40px 0px rgba(59, 64, 86, 0.15) !important;
    width: 80% !important;
    border-radius: 50px !important;
    color: #00000099 !important;
    background-position: right 7% center !important;
    /* margin: auto; */
    padding: 6px 37px 6px 7% !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    font-weight: 500;
}



input[type="range"] {
    accent-color: #E53865 !important;
}

.offcanvas_filter_input {
    border-radius: 10px !important;
    border: none !important;
    padding: 6px 4% 6px 4% !important;

}

.offcanvas_filter_input:focus {
    border: none !important;
    box-shadow: none !important;
}

.offcanvas_filter_content_section {
    overflow-y: scroll;
}

.offcanvas_height_value {
    color: rgba(0, 0, 0, 0.5);
}

.Only_show_profile_with_photos_title{
    color: rgba(0, 0, 0, 0.6) !important;
}

.input-range__label--min{
    display: none;
}

.input-range__label--max{
    display: none;
}

.input-range__track{
    background: rgb(205,205,205) !important;
}
.input-range__label{
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 15px !important;
}
.input-range__track--active{
  background: #E53865 !important;
}
.input-range__slider{
    background: #E53865 !important;
    border: 1px solid #E53865 !important; 
}
.filter_header_all_seekers_main{
     max-width:180px;
     width:180px;  
}
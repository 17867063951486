.camera_icon_main_section {
  background: #ef3672;
  border-radius: 8px;
  bottom: 2%;
  height: 30px;
  padding: 1px 5px 0px 0px;
  position: absolute;
  right: 17%;
  width: 30px;
}

.camera_icon_img {
  width: 77%;
  height: 100%;
}

.userEditPage_camera_btn {
  border: none !important;
  background-color: transparent !important;
  margin-left: 3px;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group #useredit_page_input {
  width: 100%;
  padding: 15px 10px 5px 15px;
  border: 1px solid #E53865;
  border-radius: 13px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: rgba(230, 239, 238, 0.45) !important;
}

.form-group #useredit_page_input_lable {
  position: absolute;
  left: 13px;
  top: 12px;
  font-size: 14px;
  color: #E53865;
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  background-color: transparent !important;
}

.form-group #useredit_page_input:focus+#useredit_page_input_lable,
.form-group #useredit_page_input:not(:placeholder-shown)+#useredit_page_input_lable {
  top: 0px;
  font-size: 13px;
  color: #E53865;
  background-color: #ffffff;
  padding: 0 4px;
  border-radius: 13px;
  left: 13px;
}

/* date === */
.form-group #useredit_page_input_date {
  height: 45.6px;
  width: 100%;
  padding: 10px 10px 10px 35px;
  border: 1px solid #E53865;
  border-radius: 13px;
  font-size: 16px;
  color: #000000;
  transition: border-color 0.3s ease;
  /* background-color: rgba(229, 56, 101, 0.1) !important; */
  background-color: rgba(230, 239, 238, 0.45) !important;

}

/* .form-group #useredit_page_input_date_lable {
    position: absolute;
    left: 13px;
    top: 12px;
    font-size: 14px;
    color: #E53865;
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
    background-color: transparent !important;
  } */

.form-group #useredit_page_input_date:focus+#useredit_page_input_date_lable,
.form-group #useredit_page_input_date:not(:placeholder-shown)+#useredit_page_input_date_lable {
  top: 0px;
  font-size: 12px;
  color: #E53865;
  background-color: #ffffff;
  padding: 0 4px;
  border-radius: 13px;
  left: 13px;
}

.form-group .form-control:active {
  box-shadow: none !important;

}

.form-group .form-control:focus {
  box-shadow: none !important;
}


/* location and about */
.form-group #useredit_page_location {
  width: 100% !important;
  padding: 18px 10px 15px 17px;
  border: 1px solid #E53865;
  border-radius: 13px;
  font-size: 16px;
  /* font-weight: 700 !important; */
  transition: border-color 0.3s ease;
  background-color: rgba(230, 239, 238, 0.45) !important;
}

.form-group #useredit_page_location_lable {
  position: absolute;
  left: 13px;
  top: 12px;
  font-size: 13px;
  color: #E53865;
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  background-color: transparent !important;
  /* font-weight: 700 !important; */

}

.form-group #useredit_page_location:focus+#useredit_page_location_lable,
.form-group #useredit_page_location:not(:placeholder-shown)+#useredit_page_location_lable {
  top: 0px;
  font-size: 13px !important;
  color: #E53865;
  background-color: #ffffff;
  padding: 0px 4px;
  border-radius: 13px;
  left: 13px;
  /* font-weight: 700 !important; */
}

.userdata_edit {
  width: auto !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0.375rem 2rem 0.375rem 0.75rem !important;
  box-shadow: none !important;
}

.save_update_data {
  box-shadow: 0px 10px 20px 0px #0000001A;
  color: white;
  background-color: #E53865;
  border: none;
  border-radius: 24px;
  padding: 7px 20px;
}

.user_edit_page_interest_color {
  color: #E53865 !important;
}

.user_edit_page_table_icon_section {
  width: 15%;
  color: #E53865;
  font-size: 20px;
}

.user_edit_page_table_content_section {
  width: 85%;
}

.user_edit_page_table_content_title {
  color: rgba(0, 0, 0, 0.6);
}

.user_edit_filter_cheakbox_header {
  font-family: 'Poppins-Regular' !important;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
}

.user_edit_filter_cheakbox_main_section {
  width: 25%;
}

.userEdit_required_filde {
  color: #E53865;
  /* font-size: 16px; */
}


.user_edit_multi_select .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 90%;
}

.user_edit_multi_select .css-1jqq78o-placeholder {
  margin-left: 8px !important;
}


/* ====== start to custom tab ==== */

.tab_container_main {
  width: 100%;
}

/* Tab header style */
.tab_header {
  display: flex;
}

/* Individual tab item style */
.tab_item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid #ddd;
  width: 50%;
  text-align: center;
  font-size: 25px;
}

/* Active tab item style */
.tab_item.active {
  color: #EF3672;
  border-bottom-color: #EF3672;
}

/* Tab content style */
.tab_content {
  padding: 10px 0px;
}

/* Individual tab panel style */
.tab_panel {
  display: none;
}

/* Active tab panel style */
.tab_panel.active {
  display: block;
}

/*  end to custom tab ==== */

@media(min-width:280px) and (max-width:767px) {
  .user_edit_filter_cheakbox_main_section {
    width: 50%;
  }

  .user_edit_filter_cheakbox_header {
    font-size: 15px;
  }

  .camera_icon_main_section {
    padding: 2px 5px 0px 0px;

  }

  .user_edit_multi_select .css-b62m3t-container {
    width: 90%;
  }

  .tab_item {
    font-size: 17px;
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .user_edit_filter_cheakbox_main_section {
    width: 50%;
  }

  .user_edit_filter_cheakbox_header {
    font-size: 15px;
  }

  .camera_icon_main_section {
    padding: 2px 5px 0px 0px;

  }

  .user_edit_multi_select .css-b62m3t-container {
    width: 90%;
  }
  .tab_item {
    font-size: 17px;
  }
}

@media(min-width:1024px) and (max-width:1299px) {
  .user_edit_filter_cheakbox_main_section {
    width: 33%;
  }

  .user_edit_filter_cheakbox_header {
    font-size: 14px;
  }

}

@media(min-width:1199px) and (max-width:1399px) {
  .user_edit_filter_cheakbox_header {
    font-size: 15px;
  }
}

.add_new_height {
  height: auto;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.new_calender {
  padding: 14px 15px !important;
}

.errorclassNew {
  color: black;
}
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: Montserrat-Regular;
  src: url("../src/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Poppins-Regular;
  src: url("../src/fonts/Poppins-Font/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../src/fonts/Poppins-Font/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../src/fonts/Poppins-Font/Poppins-Medium.ttf");
}

@font-face {
  font-family: Rubik-Regular;
  src: url("../src/fonts/Rubik1/Rubik-Regular.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  src: url("../src/fonts/Roboto-font/Roboto-Regular.ttf");
}

@font-face {
  font-family: DMSans-Regular;
  src: url("../src/fonts/DM_Sans/DMSans-Regular.ttf");
}

:root {
  --banner-div-height: 0px;
}


body {
  font-family: "Poppins-Regular" !important;
  background-color: #e4ebe5 !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  padding: 0;
}

.cursor_pointer_common {
  cursor: pointer;
}

.linkoverright {
  text-decoration: none !important;
  color: #E53865 !important;
}

.bio_two_line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.alert {
  border: none !important;
  height: var(--banner-div-height);
  z-index: 1;
  margin: 0% !important;
  padding: 3px 0px 0px 0px !important;
  /* position: absolute !important; */
  left: 0 !important;
  width: 100% !important;
  background-color: #E53865 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.add_height {
  height: 30px;
}

.defalut_height {
  height: 0px;
}

.alert-dismissible .close {
  padding: 14px !important;
}

.close {
  color: white;
  text-shadow: none !important;
  opacity: 1 !important;
  margin-right: 10px;
}

.close:hover {
  color: white;
  text-shadow: none !important;
  opacity: 1 !important;

}


/* ====== Interest css ==== */
.offcanvas_Interest_selector {
  width: 86% !important;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: black !important;
  height: 20px !important;
  width: 15px !important;
}

.css-13cymwt-control {
  border: 0px !important;
  border-radius: 50px !important;
  box-shadow: 0px !important;
  overflow: hidden !important;
}

.css-qbdosj-Input:focus {
  border: 0px !important;
  border-radius: 50px !important;
}

/* .css-1xc3v61-indicatorContainer{
  margin-right: 12px !important;
}
.css-1xc3v61-indicatorContainer:focus{
  margin-right: 12px !important;
} */
/* .css-15lsz6c-indicatorContainer{
  margin-right: 12px !important;
} */
.css-13cymwt-control {
  overflow: hidden !important;
}

.css-3w2yfm-ValueContainer {
  overflow: hidden !important;
}

.css-13cymwt-control:focus {
  border: 0px !important;
  border-radius: 50px !important;
  box-shadow: 0px !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-1jqq78o-placeholder {
  margin-left: 19px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  border: 0px !important;
  border-radius: 50px !important;
}

.css-t3ipsp-control:hover {
  border: 0px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}

.css-t3ipsp-control {
  border: 0px !important;
  border-radius: 50px !important;
  box-shadow: none !important;

}

.css-t3ipsp-control:focus {
  border: 0px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}

/* ==. Interest end === */

.filter_disable_section {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 50px;
  background-color: rgb(210, 215, 219);
  opacity: 0.4;
  height: 38px;
  border: 1px solid rgb(162, 165, 168);
  /* box-shadow: 0px 20px 40px 0px rgba(59, 64, 86, 0.15) !important; */
}

.blur_img_fake_contain {
  filter: blur(10px)
}

.blur_name_like_fake_contain {
  filter: blur(5px)
}

@media(min-width:280px) and (max-width:767px) {
  .alert {
    font-size: 12px;
    padding: 0px 0px 0px 0px !important;
  }

  .close {
    font-size: 20px !important;
  }
}

.capture_photo{
  width: 100%;
  height: 200px;
}
.capture_img{
  width: 79%;
  height: 200px;
}

.textboxtime{
  background-color: #6f69692e !important;
  border: 2px solid transparent;
  height: 60px;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
  color: black;
  border-radius: 5px;
  width: 70px;
}

.textboxtime:focus{
  border-color: #E53865;
   color: #E53865;
   background-color: white !important;
}
.clock-hour {
  font-size: 14px;
  font-weight: 400;
}
.apperance-doubledoticon {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
}
.clock-period {
  color: black;
  border: 1px solid #E53865;
  margin-bottom: 18px;
  border-radius: 3px;
}
.clock-am,
.clock-pm {
    padding: 3px 8px 3px 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.select-imgset{
  width: 90px;
  height: 90px;
  border-radius: 15px;
  object-fit: cover;
}

.user_icon_profile {
  background-color: rgb(210, 207, 207);
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.profile_user_icon {
  font-size: 20px;
  color: white;
}

.optioniconwidth{
  width: 20px;
  height: 20px;
}
.profile-set-button{
  position: absolute;
  bottom: 9px;
  right: -10px;
  background-color: #E53865;
  border-radius: 8px;
  padding: 1px 5px;
}

.event_card_section{
    height: 100% !important;
    width: 100% !important;
}
.event_card_section_height{
  height: 160px  !important;
}
.pac-container{
  z-index: 1055 !important;
}
@media (min-width: 320px) and (max-width: 425px) {
  .event_card_section_height {
    width: 85% !important;
    height: 120px !important;
  }
}
.event-card-main{
  height: 100%;
  padding: 6px 15px;
}

.event-card-main-div{
  height: 100%;
  padding: 6px 0px;
}

.event-card-main-event-div {
  height: 100%;
  padding: 6px 15px;
  width: 67%;
  margin: 0 auto;
}
.event-card-main-event-details-div {
  height: 100%;
  padding: 6px 15px;
  width: 67%;
  margin: 0 auto;
}


.event_card_section_main_sidebar_favo {
  position: absolute;
  top: calc(50% - 36px);
  right: 0px;
  display: flex;
  align-items: center;
  z-index: 9;
}
@media(min-width:280px) and (max-width:767px) {
  .event_card_section_main_sidebar_favo {
  top: calc(50% - 71px);

  }
  .event-card-main-event-details-div {
 
 
    width: 100%;
   
  }
}

.event_card_img_height{
  height: 250px;
}
.event_card_border_radius{
  border-radius: 15px;
}


.event_name {
  font-family: 'Poppins-Regular';
  color: black;
  font-size: 18px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

}

.event_location {
  color: black;
  font-size: 15px;
  font-family: 'Roboto-Regular';
}
.event_location-event-details {
  color: black;
  font-size: 17px;
  font-family: 'Roboto-Regular';
}

.event_location-zIndex{
  z-index: 1;
}

.event_location_color {
  color: #000000;
}

.link-text {
  text-decoration: underline;
  color: blue !important;
}

.event_name_modal{
    font-size: 13px;
    font-family: 'DMSans-Regular' !important;
    font-weight: 500 !important;
    color: #000000;
}
.event_name_modal-event-details{
    font-size: 17px;
    font-family: 'DMSans-Regular' !important;
    font-weight: 500 !important;
    color: #000000;
}
.event_type_title{
  font-size: 16px;
    font-family: 'Poppins-Regular' !important;
    font-weight: 700 !important;
    color: #000000;
    margin-bottom: 0%;
}
.comment_user_name{
  font-family: 'Poppins-Regular' !important;
  font-weight: 600 !important;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.comment_input{
    border: 2px solid black !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 20px 10px !important;
}
.comment_input:focus{
    border: 2px solid black !important;
    box-shadow: none !important;
    background-color: transparent !important;
}
@media(min-width:280px) and (max-width:767px) {
  .event_name {
    font-family: 'Poppins-Regular';
    color: black;
    font-size: 15px;
}
.event_location {
  color: black;
  font-size: 12px;
  font-family: 'Roboto-Regular';
}
}
.message-de{
  color: #E53865;
  font-weight: 700;
  font-family: "Poppins-Bold";
  background: #f8e8eb;
  border: 0px ;
  padding: 10px;
  border-radius: 10px;
}
@media(min-width:768px) and (max-width:1023px) {
  .event_name {
    font-family: 'Poppins-Regular';
    color: black;
    font-size: 15px;
}
.event_location {
  color: black;
  font-size: 12px;
  font-family: 'Roboto-Regular';
  
}

}

.ql-container {
  border: none;
  border: 2px solid #E53865 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: var(--bs-border-radius);
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  font-family: inherit !important;
  font-size: 15px !important;
}
.matched_section_contain {
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
}

.matched_section_title {
    height: 10vh;
    height: 10dvh;
    display: flex;
    align-items: center;
}

.matched_title {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
}

.matched_title_counte {
    color: #E53865;
}

.matched_section_slider {
    height: 70vh;
    height: calc(70dvh - var(--banner-div-height));
    overflow-y: scroll;
}

.matched_section_slider::-webkit-scrollbar {
    display: none;
}

.Matched_is_non_img {
    width: 100% !important;
}

.card_section_main_sidebar_Matched {
    /* border: 1px solid red; */
    position: absolute;
    /* top: 0; */
    top: calc(50% - 62.5px);
    right: 0;
    /* height: 100%; */
    display: flex;
    align-items: center;
    z-index: 9;
}

.fav_icon_heart {
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
}

.card_section_main_sidebar_favo {
    /* border: 1px solid red; */
    position: absolute;
    /* top: 0; */
    top: calc(50% - 62.5px);
    right: 0;
    /* height: 100%; */
    display: flex;
    align-items: center;
    z-index: 9;
}

@media screen and (max-width: 280px) {
    .Favourited_tab_title_name {
        font-size: 13px;
    }

    .liked_tab_title_name {
        font-size: 13px;

    }
}

@media(min-width:280px) and (max-width:767px) {
    .matched_section_contain {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .matched_section_title {
        height: 10vh;
        height: 10dvh;
        justify-content: center;
    }

    .matched_title {
        font-size: 25px;
    }

    .matched_section_slider {
        /* height: calc(63dvh - var(--banner-div-height)); */
        height: calc(72dvh - var(--banner-div-height));
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .matched_section_title {
        height: 10vh;
        height: 10dvh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .matched_section_contain {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .matched_section_slider {
        /* height: calc(63dvh - var(--banner-div-height)); */
        height: calc(72dvh - var(--banner-div-height));
    }
}
.LogOutModal_first_para {
    font-weight: 500;
    color: black;
    font-size: 17px;
}
.LogOutModal_page_warning_icon{
    width: 85px;
    height: 85px;
}

@media(min-width:280px) and (max-width:767px) {

    .LogOutModal_page_warning_icon{
        width: 60px;
        height: 60px;
    }
    .LogOutModal_first_para {
        font-size: 15px;
    }
}

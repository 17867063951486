.feedback_count_sectione{
    cursor: 'pointer';
    font-size: 24px;
    margin-right: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
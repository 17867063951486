.gallery_main_section {
  height: 80vh;
  height: calc(80dvh - var(--banner-div-height));
  z-index: 1;
  overflow-y: scroll;
}

.gallery_main_section::-webkit-scrollbar {
  display: none;
}

.gallerypage_plus_btn {
  border: none !important;
  background-color: transparent !important;
}

.gallery_set_profile_btn {
  border: 1px solid white;
  background-color: transparent !important;
  color: white !important;
  border-radius: 50px;
  padding: 3px 11px;
}

.gallery_tab_main_section {
  height: 72vh !important;
  height: calc(72dvh - var(--banner-div-height)) !important;
  overflow-y: scroll;
  position: relative;
}





.gallery_tab_main_section::-webkit-scrollbar {
  display: none;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  /* grid-gap: 10px; */
  z-index: 1;
}

.gallery__item {
  width: 100%;
  /* height: 220px !important; */
  height: 205px !important;
  object-fit: fill;
  border-radius: 10px;
  cursor: pointer;
}

.gallery_video {
  width: 100%;
  /* height: 220px !important; */
  height: 205px !important;
  object-fit: fill;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.play_icon {
  position: absolute;
  /* left: 44%; */
  /* left: calc(100% - 99px); */
  border-radius: 50%;
  background-color: #EF3672B2;
  /* top: 44%; */
  height: 35px;
  width: 35px;
  color: #FFFFFFB2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoPlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_image_model .videoPlay {
  height: auto;
  max-height: 493px;
}

.user_image_name {
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.user_image_name a {
  text-decoration: none;
  color: white;
}

.user_image_location {
  font-size: 14px;
  font-weight: 400;
  color: white;
}


.profile_model_image {
  width: 48px;
  height: 48px;
  border: 2px solid white;
  border-radius: 50%;
}

.user_image_model {
  width: 370px !important;
  margin: auto;
  position: relative;
  border-radius: 40px;
  /* overflow: hidden; */
}

.user_image_model_close {
  position: absolute;
  top: 1%;
  font-size: 21px;
  color: white;
  right: 3%;
  z-index: 999;

}

.user_image_model_footer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2%;
}

.user_image_model_footer.verification {
  padding: 10px 15px;
}

.user_post_header.verification {
  border-radius: 19px 19px 0 0 !important;
}

.user_post_footer.verification {
  border-radius: 0 0 19px 19px !important;
}

.gallery_ima_modal_footer {
  font-size: 14px;
  color: white;
}

.user_image_model .modal-body {
  /* background: #FFFFFF; */
}

.user_modal_main_section {
  aspect-ratio: 1.5/2 !important;
}

.user_modal_main_section.verification {
  height: 400px !important;
  aspect-ratio: unset !important;
  overflow: hidden;
}

.user_model_post {
  width: 100%;
  height: 494px;
  max-height: 494px;
  object-fit: fill;
}

.user_model_post.verification {
  height: 450px !important;
}

.user_model_post_video {
  width: 100%;
  height: 495px;
  max-height: 495px;
  object-fit: fill;
}

.user_post_header {
  border-bottom: none !important;
  padding: 0px;
  box-shadow: 0px 20px 40px 0px #3B405626 !important;
  background-color: #E53865;
  padding: 16px 16px 11px 16px !important;
  border-radius: 40px 40px 0px 0px !important;
}

.user_post_footer {
  border-top: none !important;
  background-color: #E53865 !important;
  border-radius: 0px 0px 40px 40px !important;

}

.user_header_main {
  display: flex;
  align-items: center;
  gap: 20px;
}

.lock_div {
  position: absolute;
  width: 96%;
  z-index: 1;
  height: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  flex-direction: column
}

.add_opacity {
  opacity: 0.2;
  filter: blur(8px);
}

.lock_image_show {
  width: 65px;
  /* position: absolute;
  top: 30%;
  right: 47%; */
}

.request_button {
  /* position: absolute;
    top: 44%;
    right: 42.5%; */
  background-color: #E53865;
  color: white;
  border: none;
  border-radius: 18px;
  padding: 5px 12px;
}

.request_button_disabled {
  background-color: rgb(162, 161, 161);
  color: white;
  border: none;
  border-radius: 18px;
  padding: 5px 12px;
  opacity: 0.4;
}

/* .pending_request_button{
  position: absolute;
  top: 44%;
  right: 43%;
} */

.delete_image {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.deleteIcon {
  background: red;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  padding: 2px;
  position: absolute;
  right: 0;
  top: 0;
}

.request_button_rejected {
  background-color: #E53865;
  color: white;
  border: none;
  border-radius: 18px;
  padding: 5px 12px;
}

.upload_img_video_gallery {
  height: 50px;
  width: 50px;
  border-radius: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  cursor: pointer;
  margin: auto;
}

.upload_img_video_gallery_no_data {
  margin-top: 57.5%;
}

@media(max-width:768px) {
  .user_image_model {
    border-radius: 5px;
    /* overflow: hidden; */
  }

  .gallery_responcive_btn {
    width: 100%;
    text-align: end;
    margin: 10px auto;
  }

  .gallery_modal_body_main {
    border-radius: 10px;
    overflow: hidden;
  }

  .gallery_set_profile_btn {
    padding: 3px 6px;
    font-size: 13px;
  }
}

@media(max-width:1023px) {
  .user_image_model {
    border-radius: 5px;
    /* overflow: hidden; */
  }

  .gallery_responcive_btn {
    width: 100%;
    text-align: end;
    margin: 10px auto;
  }

  .gallery_modal_body_main {
    border-radius: 10px;
    overflow: hidden;
  }

  .gallery_set_profile_btn {
    padding: 3px 10px;
    font-size: 14px;
  }

  .user_image_model_close {
    top: -6%;
    font-size: 21px;
    right: 0%;
  }
}

@media(min-width:280px) and (max-width:767px) {
  .gallery_main_section {
    /* height: calc(73dvh - var(--banner-div-height)); */
    height: calc(82dvh - var(--banner-div-height));
  }

  .gallery_tab_main_section {
    /* height: calc(65dvh - var(--banner-div-height)) !important; */
    /* height: calc(74.5dvh - var(--banner-div-height)) !important; */
    height: calc(80dvh - 35px - var(--banner-div-height)) !important;
    overflow-y: scroll;
  }

  .user_modal_main_section {
    /* aspect-ratio: 1/1 !important; */
  }

  .user_model_post {
    width: 100%;
    /* height: 370px;  */
    /* max-height: 370px; */
    object-fit: fill;
  }

  .user_model_post_video {
    width: 100%;
    /* height: 370px; */
    /* max-height: 370px; */
    object-fit: fill;
  }

}

@media (max-width:374px) {
  .gallery_image_padding {
    padding: 0.5rem !important;
  }
}


/* @media (max-width:375px) {
  .pending_request_button{
   right: 35%;
  }
}

@media(min-width:375px) and (max-width:425px) {
  .pending_request_button{
   right: 37%;
  }
}

@media(min-width:426px) and (max-width:768px) {
  .pending_request_button{
   right: 40%;
  }
} */

@media (max-width:375px) {
  .user_image_model {
    width: auto !important;
    margin: 0;
  }
}

@media(min-width:768px) and (max-width:1023px) {

  .gallery_ima_modal_footer {
    font-size: 13px;
  }

  .gallery_main_section {
    /* height: calc(73dvh - var(--banner-div-height)); */
    height: calc(82dvh - var(--banner-div-height));
  }

  .gallery_tab_main_section {
    /* height: calc(65dvh - var(--banner-div-height)) !important; */
    height: calc(74.5dvh - var(--banner-div-height)) !important;
    overflow-y: scroll;
  }
}
.Faq_content_main{
    height: calc(80dvh - var(--banner-div-height)); 
    overflow-y: scroll;
}
.faq_summary_title{
    font-size: 20px !important;
    font-weight: bold !important;
}

@media(min-width:280px) and (max-width:767px) {
    .Faq_content_main{
        height: calc(81dvh - var(--banner-div-height));
    }
}   
@media(min-width:768px) and (max-width:1023px) {
    .Faq_content_main{
        height: calc(81dvh - var(--banner-div-height));
    }
}
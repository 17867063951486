.green_secod_dot {
    height: 34px;
    width: 34px;
    position: inherit;
    border-radius: 100px;
    top: 72%;
    right: 9.5%;
    background-color: #4FC27E;
}

.orange_secod_dot {
    height: 15px;
    width: 15px;
    position: inherit;
    border-radius: 100px;
    top: 14.2%;
    right: 22%;
    background-color: #FF5777;
}

.visiter_smileemoji_img {
    height: 200px;
    /* height: 30%; */
    width: 200px;
    /* width: 20%; */
    position: inherit;
    border-radius: 100px;
    top: 13%;
    right: 18%;
    /* z-index: 1; */
}

.visitor_page_main_section {
    height: 80vh;
}

.visitors_title {
    font-size: 35px;
    font-weight: 600;
}

.visitors_list_main_section {
    background-color: #FFFFFFE5;
    box-shadow: 0px 7px 7px 0px #3B405626;
    border-radius: 10px;
    padding: 5px 0px;
}

.visitors_list_first_section {
    width: 10%;
    /* border: 1px solid green; */
}

.visitors_list_first_main_section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    overflow: hidden;
    height: 50px;
    border-radius: 50px;
}

.visitors_list_first_icon {
    width: 100%;
    height: 100%;
}

.visitors_list_second_section {
    width: 55%;
    /* border: 1px solid red ; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.visitors_list_thired_section {
    width: 35%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.visitors_list_user_name {
    font-size: 17px;
    font-weight: 500;
    font-size: 17px;
    font-weight: 500;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.visitors_list_user_name_custom_width{
    width: auto  !important;
}

.visitors_list_user_just_view_profile {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}

.visitors_list_city_country {
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

.visitors_list_View_her_profile {
    font-size: 17px;
    font-weight: 500;
    color: #E53865;
}

.visitor_page_tab_counte {
    color: #E53865;
}

.visiter_section_slider {
    height: 72vh !important;
    height: calc(72dvh - var(--banner-div-height)) !important;
    overflow-y: scroll;
}

.visiter_section_slider::-webkit-scrollbar {
    display: none;
}

.visitors_list_second_section_common {
    width: 33%;
    /* border: 1px solid red; */
}

.visitors_is_non_img {
    width: 100%;
}
.visitor_user_icon{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 280px) {

    .visited_tab_title_name {
        font-size: 13px;
    }
    .visitors_list_user_just_view_profile{
        font-size: 11px !important;
    }
}

@media(min-width:280px) and (max-width:767px) {
    .visitor_page_main_section {
        height: 82vh;
    }

    .visitors_list_main_section {
        padding: 5px 5px;
    }

    .visitors_list_first_section {
        width: 15%;
        /* border: 1px solid green; */
    }

    .visitors_list_first_main_section {
        width: 40px;
        height: 40px;
    }

    .visitors_list_second_section {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: space-around;
        padding-left: 11px;
    }

    .visitors_list_thired_section {
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: self-end;
        justify-content: flex-start;
        padding-right: 2%;
    }

    .visitors_list_user_name {
        font-size: 14px;
    }

    .visitors_list_user_just_view_profile {
        font-size: 12px;
        text-align: right;
    }

    .visitors_list_city_country {
        font-size: 10px;
    }

    .visitors_list_View_her_profile {
        font-size: 14px;
    }

    .visiter_section_slider {
        /* height: calc(65dvh - var(--banner-div-height)) !important; */
        height: calc(74dvh - var(--banner-div-height)) !important;
        overflow-y: scroll;
    }

    .visitors_list_second_section_common {
        width: 100%;
        /* border: 1px solid red; */
    }

}

@media(min-width:768px) and (max-width:1023px) {
    .visitor_page_main_section {
        height: 82vh;
    }

    .visitors_list_user_name {
        font-size: 16px;
    }

    .visitors_list_user_just_view_profile {
        font-size: 14px;
    }

    .visitors_list_city_country {
        font-size: 12px;

    }

    .visitors_list_View_her_profile {
        font-size: 16px;
    }

    .visiter_section_slider {
        height: calc(74dvh - var(--banner-div-height)) !important;
    }
}

@media(min-width:1024px) and (max-width:1200px) {
    .visitors_list_user_name {
        font-size: 16px;
    }

    .visitors_list_user_just_view_profile {
        font-size: 14px;
    }

    .visitors_list_city_country {
        font-size: 12px;

    }

    .visitors_list_View_her_profile {
        font-size: 16px;
    }
}

@media(min-width:1024px) and (max-width:1399px) {
    .visitors_list_first_section {
        width: 10%;
    }

    .visitors_list_second_section {
        width: 55%;
    }

    .visitors_list_thired_section {
        width: 35%;
    }
}
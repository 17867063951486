.offerModal_main .modal-content {
    background-color: white !important;
    border-radius: 5px !important;
    padding: 15px;
}

.offerModal_main .offerModal_close_icon {
    position: absolute;
    top: -6%;
    right: -2%;
    font-size: 25px;
    cursor: pointer;
}
.offerModal_image_main{
    width: 93%;
    margin: auto;
    /* border: 1px solid red; */
}
.offerModal_image_main_inimag{
    width: auto;
}
.offerModal_descrip{
    width: 93%;
    margin: auto;
}
@media(min-width:0px) and (max-width:767px) {
    .offerModal_main .offerModal_close_icon {
        position: absolute;
        top: -5%;
        right: -3%;
        font-size: 25px;
    }
}
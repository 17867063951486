.header_mobile_tab_main {
    display: flex;
    align-items: center;
    background-color: white;
}

.header_mobile_tab_logo_section {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
}

.header_mobile_tab_logo_section_loginbtnremove {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_mobile_tab_btn_section {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.header_mobile_tab_btn_section .hea_btn_btn_main {
    background-color: white !important;
    padding: 0 !important;
    /* box-shadow: 0px 1px 7px 0px rgba(229, 56, 101, 0.5) !important; */
    box-shadow: 0px 0px 0px 0px !important;
    border-radius: 50px !important;
    overflow: hidden !important;
    height: 37px !important;
    width: 37px !important;
    /* margin-right: 5% !important; */
    border: 0px solid !important;
}

.header_mobile_tab_btn_section .hea_btn_btn_main:focus {
    /* box-shadow: 0px 1px 22px 0px rgba(229, 56, 101, 0.5) !important; */
    box-shadow: 0px 0px 0px 0px !important;
    border: 0px solid !important;
}

.hea_btn_icon {
    color: black !important;
    font-size: 25px;
}

.sidebar_header_main .offcanvas {
    max-width: 90% !important;
}

.sidebar_header_section_main {
    padding: 16px 20px;
}

.sidebar_header_title_icon_section {
    width: 80%;
    display: flex;
}

.sidebar_header_icon {
    height: 54px;
    width: 54px;
    border-radius: 50px;
    overflow: hidden;
}

.sidebar_logo_img {
    width: 100%;
    height: 100%;
}

.sidebar_show_profile {
    color: gray;
    font-size: 14px;
    cursor: pointer;
}

.sidebar_header_close_btn_section {
    width: 20%;
}

.sidebar_header_close_btn_section .sidebar_hea_close {
    background-color: white !important;
    padding: 0 !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 50px !important;
    overflow: hidden !important;
    height: 35px !important;
    width: 35px !important;
    margin-right: 5% !important;
    border: 0px solid !important;
}

.sidebar_header_close_btn_section .sidebar_hea_close:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4) !important;
    border: 0px solid !important;
}

.sidebar_menu_main_sec {
    width: 70%;
}

.sidebar_icon {
    width: 90px;
}

.sidebar_icon_login {
    width: 150px;
}

.sidbar_menu_active {
    color: #EF3672 !important;
    text-decoration: none !important;
    font-family: 'Rubik-Regular';
    font-weight: 600;
    font-size: 18px !important;
}

.sidbar_menu_deactive {
    font-family: 'Rubik-Regular' !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #000000 !important;
    text-decoration: none !important;
}

.hedSeco_login_btn_main .login_btn {
    background-color: #EF3672 !important;
    color: white !important;
    border: none !important;
}

.hedSeco_login_btn_main .login_btn:focus {
    background-color: #EF3672 !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
}

#sidebar_header_main {
    max-width: 90% !important;
}

.sidebar_Upgrade_link {
    font-size: 13px;
    color: #E53865;
}

.playstore_img_mobile {
    height: 30px;
    width: 90px;
}

.applestore_img_mobile {
    height: 30px;
    width: 90px;
}


@media(max-width:280px) {
    .sidebar_header_section_main {
        padding: 16px 12px;
    }
}

@media(min-width:1023px) {
    .sidebar_icon {
        width: 150px;
    }
}
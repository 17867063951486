.main_section_subscription {
    position: relative;
    z-index: 9;
    /* background-color: #E4ECE7; */
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
    padding: 0% !important;
    margin: 0px !important;
    overflow-y: scroll;
}

.main_section_subscription::-webkit-scrollbar {
    display: none;
}

.subscription_title {
    margin-top: 1%;
    margin-bottom: 0;
    font-style: 'Poppins-Regular';
    font-weight: 600;
    font-size: 35px;
}

.Subscriptionplan_crown_icon {
    height: 80px;
    width: 80px;
}

.plan_price {
    font-size: 32px;
    /* transition: ease-out   0.7s;  */
}

.validity_days {
    font-size: 16px;
    /* transition: ease-out   0.7s;  */

}

.plan_main_section {
    /* border: 1px solid red; */
    /* display: flex; */
    /* justify-content: space-evenly; */
    width: 80%;
    margin: auto;
    /* margin: auto; */
    /* flex-direction: row; */
}

.content_page_plan_main_section {
    /* display: flex; */
    /* justify-content: space-between; */
    width: 96%;
    margin: auto;
    /* flex-direction: row; */
    /* align-items: center; */
}

.line {
    width: 100%;
}

.days_plan {
    border: 2px solid #E53865;
    /* width: 30%; */
    height: 100% !important;
    padding: 1%;
    border-radius: 48px;
    background-color: #ffffff;
}

.days_plan:hover {
    background-color: rgba(254, 235, 241);
    transition: ease-out 0.7s;
}

.days_plan:hover .line {
    transition: ease-out 0.7s;
    /* color:  rgba(254, 235, 241); */
    color: transparent;
}

.days_plan:hover .choose_plan_button {
    background-color: #E53865 !important;
    color: white !important;
    /* margin-top: 4% !important; */
    /* margin-bottom: 8% !important; */
    text-shadow: none !important;
    transition: ease-out 0.7s;
}

/* .days_plan:hover .plan_price {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    transition: ease-out 0.7s;

} */

/* .days_plan:hover .validity_days {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    transition: ease-out 0.7s;

} */

.content_page_days_plan {
    border: 2px solid #E53865;
    /* width: 30%; */
    height: 100% !important;
    padding: 1%;
    border-radius: 48px;
    background-color: #ffffff;
}

.content_page_days_plan:hover {
    background-color: rgba(254, 235, 241);
    transition: ease-out 0.7s;
}

.content_page_days_plan:hover .line {
    transition: ease-out 0.7s;
    /* color:  rgba(254, 235, 241); */
    color: transparent;
}

.content_page_days_plan:hover .choose_plan_button {
    background-color: #E53865 !important;
    color: white !important;
    /* margin-top: 4% !important; */
    /* margin-bottom: 8% !important; */
    text-shadow: none !important;
    transition: ease-out 0.7s;
}

/* .content_page_days_plan:hover .plan_price {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    transition: ease-out 0.7s;

} */

/* .content_page_days_plan:hover .validity_days {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    transition: ease-out 0.7s;

} */


.choose_plan_button {
    background-color: transparent;
    border: 2px solid #E53865;
    border-radius: 25px;
    font-size: 18px;
    padding: 2px 5px;
    color: #EF3672;
    text-shadow: 2px 3px 2px rgba(228, 236, 231, 0.7);
    /* transition: ease-out  0.7s; */
}

.subscriptionplan_right_wrong_img {
    height: 25px;
    width: 25px;
}

.plan_Expiry_Date {
    color: #EF3672;

}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 25px;
    line-height: 1;
    opacity: 1;
    color: #EF3672;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Subscriptionplan_description_in_card {
    height: 250px;
    overflow-y: scroll;
}

.Subscriptionplan_description_in_card::-webkit-scrollbar {
    display: block !important;
    width: 2px;

}

.Subscriptionplan_description_in_card::-webkit-scrollbar-thumb {
    background: #E53865;
    border-radius: 50px;
}

@media(min-width:280px) and (max-width:767px) {
    .main_section_subscription { 
        background-color: #E4ECE7;
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height)); 
    }

    .Subscriptionplan_crown_icon {
        height: 50px;
        width: 60px;
    }

    .plan_Expiry_Date {
        color: #EF3672;
        font-size: 14px;
        width: 95%;
        margin: auto;
    }

    .subscription_title {
        font-size: 26px;
    }

    .plan_main_section {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        flex-direction: column;
        padding: 0% 0%;
    }

    .content_page_plan_main_section {
        /* display: flex; */
        /* justify-content: space-between; */
        width: 90%;
        margin: auto;
        /* flex-direction: column; */
        padding: 0% 0%;
    }

    .slick-prev {
        left: -30px;
    }

    .content_page_days_plan {
        width: 100%;
    }

    .days_plan {
        border: 2px solid #E53865;
        /* width: 100%; */
        height: auto;
        padding: 1%;
    }

    .plan_price {
        font-size: 25px;
    }

    .validity_days {
        font-size: 13px;
    }

    .Subscriptionplan_short_title {
        font-size: 18px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .plan_main_section {
        /* border: 1px solid red; */
        /* display: flex;
        justify-content: space-between; */
        width: 80%;
        /* margin: auto;
        flex-direction: column;
        align-items: center;
        padding: 0% 0% 5% 0%; */
    }
    .main_section_subscription { 
        background-color: #E4ECE7;
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height)); 
    }

    .content_page_plan_main_section {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        flex-direction: column;
        padding: 0% 0%;
    }

    .content_page_days_plan {
        width: 96%;
    }
    .slick-prev {
        left: -30px;
    }


    .days_plan {
        /* width: 50%; */
    }
}

@media(min-width:1023px) and (max-width:1199px) {
    .plan_main_section {
        /* border: 1px solid red; */
        /* display: flex; */
        /* justify-content: space-evenly; */
        width: 80%;
        /* margin: auto; */
        /* flex-direction: row; */
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .plan_main_section {
        /* border: 1px solid red; */
        /* display: flex; */
        /* justify-content: space-evenly; */
        width: 80%;
        /* margin: auto; */
        /* flex-direction: row; */
    }
}

@media(min-width:715px) and (max-width:717px) {
    .Subscriptionplan_description_in_card {
        height: 175px !important;
    }
}

.add_new_skeleton_class {
    width: 80%;
    margin: auto;
}
.main_section {
    height: 100% !important;
    width: 100vw;

    /* position: relative; */
    position: fixed;
    top: 0;
}

.background_section_main {
    position: absolute;
    top: 0;
    height: 100vh;
    height: 100dvh;
    right: 0;
    width: 100vw;
    background-color: #e6efed;
    overflow: hidden;
}

.header_main {
    position: sticky;
    top: 0;
    z-index: 999;
    height: 10vh;
    height: 10dvh;
    padding: 0px 12px;
}

.header_logo_img {
    width: 40;
    height: 40px;
}

.content_main {
    height: calc(80dvh - var(--banner-div-height));
    overflow-y: scroll;
    /* border: 1px solid blue; */
}

.content_second {
    height: calc(80dvh - var(--banner-div-height));
    /* height: 80vh; */
    overflow-y: scroll;
}

.Home_Private_Me_premium_icon {
    width: 18px;
    margin-top: -4px;
}

.footer_main {
    position: fixed !important;
    bottom: 0;
    height: 10vh;
    height: 10dvh;
    display: flex;
    align-items: center;
}

.footer_main_chat_color {
    background-color: #E53865;
}

.footer_main_mobile_ipad {
    position: fixed !important;
    bottom: 0;
    height: 9dvh;
    display: flex;
    align-items: center;
}

.user_dropdown {
    position: relative;
    border-radius: 50px !important;
    background-color: white !important;
    max-height: 40px !important;
    /* width: auto !important; */
    box-shadow: 1.1px 1px 5px -0.5px #888888 !important
}

.user_dropdown .user_dropdown_selector {
    margin-left: 40px !important;
    font-size: 14px !important;

}

.navbar_menu_section {
    font-family: 'Rubik-Regular' !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #000000 !important;
    text-decoration: none !important;
}

#collasible-nav-dropdown-Activity {
    margin-left: 0% !important;
}

.user_dropdown_selector .dropdown-menu.show {
    display: block;
    /* margin-left: -72%; */
}

.navbar_menu_home_title {
    color: #EF3672 !important;
    text-decoration: none !important;
    font-family: 'Rubik-Regular';
    font-weight: 600;
    font-size: 18px !important;
}

.navbar_user_detail_dropdown {
    width: 34% !important;
    position: relative;
}

.user_dropdown_img {
    border-radius: 50% !important;
    position: absolute;
    /* width: 28%; */
    width: 40px;
    height: 100%;
    /* object-fit: cover; */
}

.navabar_brand_logo {
    width: 75%;
}

.premium_user_link {
    position: absolute;
    top: -61%;
    left: 5.5%;
    z-index: 1;
    cursor: pointer;
    color: #EF3672
}

.premium_user_icon_10 {
    position: absolute;
    top: -44%;
    height: 18px;
    width: 23px;
    left: 5.5%;
    z-index: 1;
}

.premium_user_icon_5 {
    position: absolute;
    top: -44%;
    height: 18px;
    width: 23px;
    left: 7%;
    z-index: 1;
}

.premium_user_icon_9 {
    position: absolute;
    top: -44%;
    height: 18px;
    width: 23px;
    left: 7%;
    z-index: 1;
}

.custom-height {
    height: 30px;
    width: 30px;
    cursor: pointer !important;
}

.content_pink_icon {
    width: 40% !important;
    margin: 2% 0% 0% -31%;
}

.content_blackline {
    width: 50% !important;
    position: absolute;
    left: 16%;
    /* z-index: -1; */
    top: -1%;
}

.welcome_name {
    font-size: 20px;
    font-family: 'Montserrat-Regular', sans-serif;
}

.meet_name {
    font-size: 30px;
    font-family: 'Montserrat-Regular', sans-serif;
}

.meetnameblackline {
    width: 30% !important;
    position: absolute;
    bottom: -19%;
    right: 0%;
}

.content_user_img {
    height: 200px;
    width: 200px;
    border-radius: 100%;
    /* box-shadow: 0px 0px 25px rgba(255, 105, 180, 0.7); */
    box-shadow: 0px 0px 25px rgba(239, 54, 114, 0.5);
    overflow: hidden;
}

.content_user_img img {
    height: 100%;
    width: 100%;
}

.offLocation_in_home_page {
    width: 300px;
}
.offLocation_para_home{
    font-size: 19px;
}

.slide_main_section {
    height: 230px;
    /* position: relative; */
}

.content_second::-webkit-scrollbar {
    display: none;
}

.slider_img {
    /* background-color: rgba(227, 18, 18, 0.5); */
    background-color: rgba(251, 37, 121, 0.7);
    position: absolute;
    top: 0;
    height: inherit;
    width: inherit;
}

.Go_To_Filter_Clear {
    color: #EF3672;
    cursor: pointer;
}

.fake_slider_img {
    position: absolute;
    top: 0;
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fake_private_image {
    width: 65px;
}

.content_top_chat_main {
    position: absolute;
    top: 0px;
    right: 0px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    z-index: 9;
}

.content_bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: inherit;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    padding-left: 5%;
    padding-bottom: 1%;
    /* border: 1px solid red; */
}

.content_slider_name {
    font-family: 'Poppins-Regular';
    color: white;
    font-size: 18px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

.content_slider_description {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Roboto-Regular';
}

.card_content_section {
    height: 100% !important;
    width: 100% !important;
    /* position: relative; */
}

.card_background_img {
    height: 100% !important;
    width: 100%;
}

.card_background_img-event-details{
    height: 100% !important;
    width: 100%;
}
.img-width{
    width: 350px !important;
    height: 60% !important;
    
}
@media screen and (max-width: 425px) {
    .img-width {
        width: 165px !important;
        height: 47% !important;
    }
}




.custom-userDetails-title-name{
    width: 52% !important;
} 
.text-break {
   
    white-space: normal; /* Prevent text from staying on a single line */
    width: 100%;

    font-family: 'Poppins-Regular';
    color: black;
    /* font-size: 12px; */
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .text-break-events {
    white-space: normal; /* Prevent text from staying on a single line */
    width: 90%;

    font-family: 'Poppins-Regular';
    color: black;
    font-size: 12px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .text-break-comment {
    white-space: normal; /* Prevent text from staying on a single line */
    width: 100%;

    font-family: 'Poppins-Regular';
    color: rgb(190, 184, 184);
    font-size: 12px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .comment-user-image{
    height: 30px;
    width: 30px;
  }

  .text-break-comment-event-details{
    white-space: normal; /* Prevent text from staying on a single line */
    width: 100%;

    font-family: 'Poppins-Regular';
    color: black;
    font-size: 12px;
    
    
    
  }
  
  .text-break-event-details{
    white-space: normal;
      width: 90%;
      font-family: 'Poppins-Regular';
      color: black;
      font-size: 17px !;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
.modal_open {
    animation: ease-in-out modalopenanimation 0.4s;
}

@keyframes modalopenanimation {
    0% {
        transform: scale(0);
    }

    10% {
        transform: scale(0.1);
    }

    20% {
        transform: scale(0.2);
    }

    30% {
        transform: scale(0.3);
    }

    40% {
        transform: scale(0.4);
    }

    50% {
        transform: scale(0.5);
    }

    60% {
        transform: scale(0.6);
    }

    70% {
        transform: scale(0.7);
    }

    80% {
        transform: scale(0.8);
    }

    90% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.close_modal {
    animation: ease-in-out modalcloseanimation 0.6s;
}

@keyframes modalcloseanimation {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(0.9);
    }

    20% {
        transform: scale(0.8);
    }

    30% {
        transform: scale(0.7);
    }

    40% {
        transform: scale(0.6);
    }

    50% {
        transform: scale(0.5);
    }

    60% {
        transform: scale(0.4);
    }

    70% {
        transform: scale(0.3);
    }

    80% {
        transform: scale(0.2);
    }

    90% {
        transform: scale(0.1);
    }

    100% {
        transform: scale(0);
    }
}


.modal_intersts_icon {
    color: #000000;
}

.Meet_the_highlight {
    color: #FFC247;
}

.icon_heart {
    height: 26px !important;
    width: 26px !important;
    cursor: pointer;
}

.likeheart {
    animation: heartbeatwhite 0.5s 1;
}

@keyframes heartbeatwhite {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.likeheartmodal {
    animation: heartbeatwhitemodal 0.5s 1;
}

@keyframes heartbeatwhitemodal {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.icon_heart_red {
    height: 26px !important;
    width: 26px !important;
    cursor: pointer;
}

.content_slider_description_color {
    color: #FFC247;
}

/* .unlikeheart{
    animation: heartbeatred 0.5s 1;
}

@keyframes heartbeatred {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
} */

/* modal css====== */

/* .icon_heart_modal {
    height: 26px !important;
    width: 26px !important;
    animation: heartbeatwhitemodal 0.5s 1;

}

@keyframes heartbeatwhitemodal {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}


.icon_heart_red_modal {
    height: 26px !important;
    width: 26px !important;
    animation: heartbeatredmodal 0.5s 1;
}

@keyframes heartbeatredmodal {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
} */

.mobile_show {
    display: none;
}

.all_show {
    display: block;

}

.Mobile_Ipad_main_clr{
    background-color: white;
}

.footer_link {
    font-family: 'Poppins-Regular';
    font-size: 14px !important;
    color: white !important;
    margin-right: 2% !important;
    text-align: center;
}

.footer_Rights {
    font-size: 16px !important;
    color: white;
    font-family: 'Poppins-Regular';
    padding-top: 5px;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
}

.footer_apple_play {
    display: flex !important;
    justify-content: flex-end !important;
}

.footer_mobile_main_section {
    position: relative;
    background-color: #e53865;
    z-index: 1;
}

.navbar-light .navbar-nav .nav-link {
    color: black;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: black;
}

.yellow_dot {
    height: 18px;
    width: 18px;
    position: inherit;
    border-radius: 100px;
    top: 18%;
    /* left: calc(34% - 18px); */
    left: 32%;
    background-color: #FFC247;
}

.green_dot {
    height: 34px;
    width: 34px;
    position: inherit;
    border-radius: 100px;
    top: 68%;
    /* left: calc(12% - 34px);  */
    left: 6.5%;
    background-color: #4FC27E;
    /* z-index: 1; */
}


.Whitedot_first {
    height: 49px;
    width: 49px;
    position: inherit;
    border-radius: 100px;
    top: 33%;
    /* left: calc(15% - 49px); */
    left: 8%;
    /* background-color: rgba(255, 255, 255, 0.6); */
    /* z-index: 1; */

}

.Whitedot_second {
    height: 80px;
    width: 80px;
    position: inherit;
    border-radius: 100px;
    top: 6.5%;
    right: 35%;
}

.CurveBlackline_img {
    height: 20%;
    width: auto;
    position: inherit;
    top: 43%;
    right: 23%;
}

.footer_orangeline {
    position: inherit;
    height: 100%;
    width: 98%;
    margin-left: 2%;
}

.footer_greenline {
    position: inherit;
    height: 98%;
    top: 1%;
    width: 87%;
    margin-left: 6.5%;
}

.footer_ellipse_img {
    position: inherit;
    bottom: -4%;
    width: 100%;
}

.footer_blackline_img {
    position: inherit;
    bottom: -1%;
    width: 100%;
}

.bell_notification {
    position: absolute;
    top: 7px;
    right: 2px;
    background-color: #F74A4A;
    height: 10px;
    border: 1px solid #FEFEFE;
    width: 10px;
    border-radius: 50%;
}

.fssliders_section {
    position: fixed;
    bottom: 13%;
    z-index: 999;
    right: 18%;
    height: 50px;
    width: 50px;
    border-radius: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    cursor: pointer;
}

.fssliders_icon {
    color: white;
    font-size: 26px;
}

.playstore_img {
    height: 38px;
    width: 109px;
}

.applestore_img {
    height: 38px;
    width: 109px;
}

.card_main {
    position: relative;
    padding: 0px !important;
    cursor: pointer !important;
}

.card_heart_main {
    position: absolute;
    bottom: 5%;
    right: 5%
}

.dropdown-toggle {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}


.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    content: url("../../images/select_down.png") !important;
    vertical-align: -4px !important;
    /* vertical-align: 0.255em;
    content: ""; */
    border-top: 0px solid !important;
    border-right: 0px solid transparent !important;
    border-bottom: 0px !important;
    border-left: 0px solid transparent !important;
}

.navbar_center_content {
    width: 66%;
}

.Bs_Chat_Dots_icon {
    font-size: 27px;
    cursor: pointer;
}

.dropdown-item:hover {
    color: #E53865 !important;
    background-color: transparent !important;
}

.dropdown-item:focus {
    color: white !important;
    background-color: #E53865 !important;

}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    overflow: hidden !important;

}

.header_messages_notification_dot {
    position: absolute;
    top: 7%;
    height: 17px;
    width: 17px;
    right: -12%;
    border-radius: 50px;
    background-color: #E53865;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_messages_notification_dot span {
    color: white;
    font-size: 11px;
}

.home_filter_count_dot_main_section {
    position: absolute;
    top: -12%;
    right: -2%;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: #E53865;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_filter_count {
    color: white;
    font-size: 15px;
}

.filter_fieldset_border {
    border-top: 1px solid rgb(150, 148, 148, 0.7);
    padding: 0px;
}

.filter_group_name {
    color: #E53865;
    width: auto;
    margin: auto;
    padding: 0px 4px;
}

.range_custom {
    margin-left: 2.5%;
}

.smart_banner_main {
    position: absolute;
    z-index: 9999;
    height: auto;
    width: 100%;
    display: flex;
    padding: 7px 7px 7px 14px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.smart_img_main {
    height: 45px;
    width: 45px;
    border-radius: 8px;
    overflow: hidden;
}

.smart_app_logo {
    height: 100%;
    width: 100%;
}

.smart_view_btn {
    background-color: #E53865;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 1rem;
    /* padding: 5px; */
}

.smart_close_icon_main {
    position: absolute;
    right: 3px;
    top: 0px;
    z-index: 99999;
}

/* .smart_close_icon {
    color: white;

} */

.smart_title {
    /* color: white; */
    font-size: 13px;
}

.smart_title_secon {
    /* color: white; */
    font-size: 10px;
}

@media(max-width:280px) {
    .offLocation_in_home_page {
        width: 200px;
    }
    .smart_banner_main {
        padding: 7px 7px 7px 7px;
    }
    .smart_title_secon {
        font-size: 9px;
    }
    .smart_view_btn {
        font-size: 12px;
        margin-right: 0px!important;
    }
    .smart_title {
        font-size: 12px;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .form-select {
        padding: 0.375rem 2.25rem 0.375rem 10px !important;
    }

    .premium_user_link {
        position: absolute;
        top: -53%;
    }

    .footer_menu_icon_section {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* z-index: 1; */
    }

    .footer_menu_main {
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background-color: white !important;
        color: #E53865 !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .footer_menu_main_active {
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        color: white !important;
        background-color: #E53865 !important;
    }

    .footer_icon_menu {
        height: 60%;
        width: 60%;
    }

    .footer_main_mobile_ipad {
        position: fixed !important;
        bottom: 0;
        height: 9dvh;
        display: flex;
        align-items: center;
    }

}


@media(min-width:280px) and (max-width:767px) {
    .content_user_img {
        height: 100px;
        width: 100px;
        border-radius: 100%;
        overflow: hidden;
    }

    .navbar_menu_section {
        font-family: 'Rubik-Regular' !important;
        font-weight: 600 !important;
        font-size: 14px !important;
    }

    .navbar_menu_home_title {
        font-family: 'Rubik-Regular';
        font-weight: 600;
        font-size: 14px !important;
    }

    .welcome_name {
        font-size: 18px;
    }

    .meet_name {
        font-size: 28px;
    }

    .header_main {
        position: sticky;
        top: 0;
        z-index: 999;
        height: 9dvh;
        padding: 0px 0px !important;
        /* border: 1px solid red; */
    }

    .navabar_brand_logo {
        width: 60%;
    }

    .user_dropdown_selector .dropdown-menu.show {
        display: block;
        /* margin-left: -50%; */
    }

    .content_main {
        /* height: calc(70dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
        position: relative;
    }

    .content_second {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
        overflow-y: scroll;
    }

    .footer_main {
        position: fixed !important;
        bottom: 0;
        /* height: 14vh; */
        /* height: 14dvh; */
        background-color: #E53865;
    }

    .footer_ellipse_img {
        position: inherit;
        bottom: 0px;
        width: 100%;
        height: 25%;
        display: none;
    }

    .footer_blackline_img {
        position: inherit;
        bottom: 0px;
        width: 100%;
        height: 15%;
        display: none;
    }

    .slide_main_section {
        /* height: 170px; */
        height: 185px;
        /* position: relative; */
    }

    .mobile_show {
        display: block;
    }

    .all_show {
        display: none;

    }

    .footer_apple_play {
        display: flex !important;
        justify-content: space-evenly !important;

    }

    .footer_link {
        font-size: 9px !important;
        color: white !important;
        margin-right: 1.5% !important;
    }

    .footer_Rights {
        font-size: 11px !important;
        color: white;
        padding-top: 0px;
        text-align: center;
    }

    .bell_notification {
        position: absolute;
        top: 7px;
        left: 20px;
        background-color: red;
        height: 8px;
        border: 1px solid white;
        width: 8px;
        border-radius: 50%;
    }

    .fssliders_section {
        position: fixed;
        /* bottom: 21%; */
        z-index: 999;
        right: 8%;
        /* height: 40px; */
        /* width: 40px; */
        border-radius: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
    }

    .fssliders_icon {
        color: white;
        /* font-size: 23px; */
    }

    .user_dropdown {
        /* width: 100% !important; */
        width: fit-content !important;
    }

    .content_slider_name {
        font-family: 'Poppins-Regular';
        color: white;
        font-size: 15px;
    }

    .content_slider_description {
        color: #ffffff;
        font-size: 12px;
        font-family: 'Roboto-Regular';
    }

    .playstore_img {
        height: 25px;
        width: 85px;
    }

    .applestore_img {
        height: 25px;
        width: 85px;
    }

    .card_heart_main {
        position: absolute;
        bottom: 3%;
        right: 5%;
    }

    .navbar_nav_content {
        background-color: #e6efed;
        padding-left: 5% !important;
    }

    .navbar-toggler {
        margin-right: 5% !important;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none !important;
    }

    .Bell_main_section {
        margin: 14% 0%;
    }

    .footer_content_like_section {
        justify-content: space-evenly;
    }

    .header_messages_notification_dot {
        top: 9%;
        height: 15px;
        width: 15px;
        left: 16px;
    }

    .header_messages_notification_dot span {
        font-size: 13px;
    }

    .footer_messages_notification_dot {
        position: absolute;
        top: 7%;
        height: 13px;
        width: 13px;
        right: -12%;
        border-radius: 50px;
        background-color: #E53865;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer_messages_notification_dot span {
        font-size: 11px;
    }

    .home_filter_count_dot_main_section {
        top: -12%;
        right: -2%;
        height: 16px;
        width: 16px;
    }

    .home_filter_count {
        color: white;
        font-size: 12px;
    }

    .premium_user_icon_10 {
        left: 7%;
    }

    .premium_user_icon_9 {
        left: 9%;
    }

    .premium_user_icon_5 {
        left: 10%;
    }
}




@media(min-width:768px) and (max-width:1023px) {
    .header_main {
        height: 9dvh;
    }

    .content_main {
        height: calc(82dvh - var(--banner-div-height));
        position: relative;
    }

    .content_user_img {
        height: 150px;
        width: 150px;
        border-radius: 100%;
        overflow: hidden;
    }

    .footer_messages_notification_dot {
        position: absolute;
        top: 7%;
        height: 13px;
        width: 13px;
        right: -12%;
        border-radius: 50px;
        background-color: #E53865;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer_messages_notification_dot span {
        font-size: 13px;
    }

    .footer_main_mobile_ipad {
        position: fixed !important;
        bottom: 0;
        height: 9dvh;
        display: flex;
        align-items: center;
    }

    .navabar_brand_logo {
        width: 60%;
    }

    .header_main {

        padding: 0px 0px !important;

    }

    .premium_user_link {
        position: absolute;
        top: -53%;
    }

    .navbar-light .navbar-toggler {
        margin-right: 2% !important;
    }

    .welcome_name {
        font-size: 18px;
    }

    .meet_name {
        font-size: 28px;
    }

    .content_second {
        height: calc(82dvh - var(--banner-div-height));
        overflow-y: scroll;
    }

    .slide_main_section {
        height: 300px;
        /* position: relative; */
    }

    .card_content_section {
        height: 100% !important;
        width: 100% !important;
        position: relative;
    }

    .card_background_img {
        height: 60% !important;
        width: 100%;
        position: relative;
    }

    .content_slider_name {
        font-family: 'Poppins-Regular';
        color: white;
        font-size: 20px;
    }

    .content_slider_description {
        color: #ffffff;
        font-size: 18px;
        font-family: 'Roboto-Regular';
    }

    .icon_heart {
        height: 38px !important;
        width: 38px !important;
    }

    .icon_heart_red {
        height: 38px !important;
        width: 38px !important;
    }

    .mobile_show {
        display: none;
    }

    .all_show {
        display: block;

    }

    .bell_notification {
        position: absolute;
        top: 7px;
        left: 20px;
        background-color: red;
        height: 8px;
        border: 1px solid white;
        width: 8px;
        border-radius: 50%;
    }

    .fssliders_section {
        position: fixed;
        /* bottom: 14%; */
        z-index: 999;
        /* right: 12%; */
        right: 8%;
        /* height: 60px; */
        /* width: 60px; */
        border-radius: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
    }

    .fssliders_icon {
        color: white;
        /* font-size: 34px; */
    }

    .footer_main {
        background-color: #E53865;
    }

    .footer_ellipse_img {
        position: inherit;
        bottom: 0px;
        width: 100%;
        height: 22% !important;
        display: none;
    }

    .footer_blackline_img {
        position: inherit;
        bottom: 0px;
        width: 100%;
        height: 15%;
        display: none;
    }

    .user_dropdown {
        width: fit-content;
    }

    .footer_link {
        font-size: 12px !important;
        color: white !important;
        margin-right: 2% !important;
    }

    .footer_Rights {
        font-size: 14px !important;
        color: white;
    }

    .navbar_nav_content {
        background-color: #e6efed;
        padding-left: 3% !important;
    }

    .navbar-toggler {
        /* margin-right: 5% !important; */
    }

    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none !important;
    }

    .Bell_main_section {
        margin: 7% 0%;
    }

    .header_messages_notification_dot {
        left: 16px;
    }

    .home_filter_count_dot_main_section {
        top: -6%;
        right: 0%;
    }

    .premium_user_icon_10 {
        left: 6%;
    }

    .premium_user_icon_9 {
        left: 7%;
    }

    .premium_user_icon_5 {
        left: 7%;
    }


    .footer_menu_icon_section {
        width: 20%;
        /* background-color: #E53865; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer_menu_main {
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background-color: white !important;
        color: #E53865 !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .footer_menu_main_active {
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        color: white !important;
        background-color: #E53865 !important;
    }

    .footer_icon_menu {
        height: 60%;
        width: 60%;
    }

}

@media(min-width:1024px) and (max-width:1300px) {
    .slide_main_section {
        height: 300px;
        /* position: relative; */
    }

    .navbar_center_content {
        width: 70%;
    }
}

@media(min-width:1024px) and (max-width:1199px) {
    .premium_user_icon_10 {
        left: 5.5%;
        top: -32%;
        height: 12px;
    }

    .premium_user_icon_9 {
        left: 7%;
        top: -32%;
        height: 12px;
    }

    .premium_user_icon_5 {
        left: 7%;
        top: -32%;
        height: 12px;
    }

    .premium_user_link {
        top: -42%;
        font-size: 12px;
    }
}

@media(min-width:1200px) and (max-width:1299px) {
    .premium_user_icon_10 {
        left: 5.5%;
        top: -32%;
        height: 12px;
    }

    .premium_user_icon_9 {
        left: 7%;
        top: -32%;
        height: 12px;
    }

    .premium_user_icon_5 {
        left: 7%;
        top: -32%;
        height: 12px;
    }

    .premium_user_link {
        top: -42%;
        font-size: 12px;
    }
}

@media(min-width:1300px) and (max-width:1399px) {
    .premium_user_icon_10 {
        left: 5.5%;
        top: -32%;
        height: 12px;
    }

    .premium_user_icon_9 {
        left: 7%;
        top: -32%;
        height: 12px;
    }

    .premium_user_icon_5 {
        left: 7%;
        top: -32%;
        height: 12px;
    }

    .premium_user_link {
        top: -42%;
        font-size: 12px;
    }
}

@media(min-width:1024px) and (max-width:1200px) {
    .footer_ellipse_img {
        position: inherit;
        bottom: 0%;
        width: 100%;
    }

    .footer_blackline_img {
        position: inherit;
        bottom: 0%;
        width: 100%;
    }

    .welcome_name {
        font-size: 18px;
        font-family: 'Montserrat-Regular', sans-serif;
    }

    .meet_name {
        font-size: 24px;
        font-family: 'Montserrat-Regular', sans-serif;
    }

    .footer_link {
        font-size: 12px !important;
    }

    .footer_Rights {
        font-size: 14px !important;
    }

    .meetnameblackline {
        width: 30% !important;
        position: absolute;
        bottom: -14%;
        right: 0%;
    }

    .navbar_menu_section {
        font-family: 'Rubik-Regular';
        font-weight: 600;
        font-size: 16px !important;
    }

    .navbar_menu_home_title {
        font-family: 'Rubik-Regular';
        font-weight: 600;
        font-size: 16px !important;
    }

    .white_dot {
        height: 49px;
        width: 49px;
        position: inherit;
        border-radius: 100px;
        top: 27%;
        /* left: calc(15% - 49px); */
        left: 10%;

        background-color: rgba(255, 255, 255, 0.6);
    }
}

@media(min-width:1024px) and (max-width:1440px) {
    .fssliders_section {
        position: fixed;
        /* bottom: 19%; */
        z-index: 999;
        right: 8%;
        height: 50px;
        width: 50px;
        border-radius: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
    }


}

@media(min-width:280px) and (max-width:374px) {
    .logoset {
        width: 55% !important;
    }
}

@media(min-width:375px) and (max-width:424px) {
    .logoset {
        width: 50% !important;
    }

    .slide_main_section {
        height: 240px;
    }
}

@media(min-width:425px) and (max-width:767px) {
    .logoset {
        width: 44% !important;
    }

    .slide_main_section {
        height: 240px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .logoset {
        width: 20% !important;
    }
}

@media(min-width:1024px) and (max-width:1399px) {
    .logoset {
        width: 15% !important;
    }
}

@media(min-width:1400px) {
    .logoset {
        width: 13% !important;
    }
}

/* @media(max-width:380px){
    .text-break {
        font-size: 10px;
      }
      
} */
.border-remove {
    border: none !important;
}

.border-login-input {
    width: 100%;
    border: 2px solid #EF3672;
    border-radius: 10px;
    padding: 5px;
    background: white;
}

.border-remove.form-control:focus {
    box-shadow: none !important;
}

.border-remove.form-select:focus {
    box-shadow: none !important;
}

.btncustom {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3) !important;
}

.genderimg {
    width: 79%;
}

.login-button-colour {
    padding: 10px;
    background-color: #EF3672 !important;
    color: white;
    border-radius: 30px;
    width: 85%;
    cursor: pointer;
}

.loginmodalcenter {
    height: 90dvh !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.login-second-bg {
    /* background-image: url("../../images//Loginpage/Rectangle\ 3.png"); */
    height: 100%;
    background: linear-gradient(228deg, #ff95b9 0%, #fd5c91 51%);
}

.roundimg {
    object-fit: contain;
    width: 100%;
    height: 65dvh;
}

.logindetalis {
    display: flex !important;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.divcenter {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 300px !important;
}

.main_log_page {
    min-width: 263px !important;
}

.login_email_num_main {
    width: 80%;
    margin: auto;
}

.login_email_num_btn {
    border: none !important;
    padding: 10px 20px;
    background-color: #EF3672 !important;
    color: white;
    border-radius: 30px;
    width: 100%;
    font-size: 15px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3) !important;
}

.seconddivcenter {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.loginfont {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    font-family: "Poppins-Bold";
}

.Welcoming_title_login {
    color: #EF3672;
    font-size: 15px;
}

.loginfontverification {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
}

.layerclass {
    width: 99%;
    position: absolute;
    height: 90dvh;
    left: 0;
}

.ReactInputVerificationCode__item.is-filled {
    border-radius: 19px !important;
    background-color: #EF3672 !important;
    color: white;
}

.ReactInputVerificationCode__item.is-active {
    border-radius: 19px !important;
    border: 1px solid #EF3672 !important;
    outline: none;
    box-shadow: none !important;
}

.ReactInputVerificationCode__input {
    left: 0 !important;
    height: 0 !important;
}

.resend-code {
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3) !important;
    margin: 10px;
    border: none;
}

.time_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.arrowback {
    position: absolute;
    top: 4%;
}

.borderselct {
    border-radius: 10px 0px 0px 10px !important;
    width: auto !important;
}

.borderinput {
    border-radius: 0px 9px 8px 0px !important;
}

.Term_conditions_mainsection {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Term_conditions_main_login {
    display: flex;
    align-items: center;
}

.Term_conditions_main_login .form-check-input {
    padding: 1.2% !important;
    margin-top: 0px !important;
    margin-left: 0% !important;
}

.Term_conditions_main_login .form-check-input:checked {
    background-color: #EF3672 !important;
    box-shadow: none !important;
    border: 1px solid #cf275c !important;
}

.login_locatione_main_section .modal-body {
    padding: 25px;
}

.login_locatione_close_main {
    background-color: #EF3672;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: Poppins-Medium;
}

.login_locatione_title {
    font-size: 17px;
}

.login_locatione_close {
    font-size: 25px;
}

.login_locatione_btn {
    background-color: #EF3672;
    color: white;
    border: none !important;
    padding: 8px 33px;
    border-radius: 5px;
}

.Claima_email {
    width: 100%;
    border: 2px solid #EF3672;
    border-radius: 10px;
    padding: 5px;
    background: white;
}

/* @media (max-width:319px) {
    .borderselct{
        padding: 0.375rem 2.25rem 0.375rem 0rem !important;
    }
} */
.with_out_login_page_header {
    height: 90dvh;
    overflow-y: scroll;
}

.login_app_play_store {
    width: 100px;
    height: 30px;
}

.FAQ_Terms_of_Use_title {
    font-size: 15px;
}

@media(max-width:1023px) {
    .with_out_login_page_header {
        height: 91dvh;
        overflow-y: scroll;
    }

    .layerclass {
        height: 91vh;
        width: 99%;
        position: absolute;
        left: 0;
    }

    .loginmodalcenter {
        height: 91vh !important;
        overflow-y: scroll;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
    }
}

@media(max-width:280px) {
    .login_email_num_btn {
        padding: 10px 10px !important;
        font-size: 12px !important;
    }

    .divcenter {
        min-width: auto !important;
    }

    .login_email_num_main {
        width: 100% !important;
        margin: auto;
    }

    .login_locatione_title {
        font-size: 13px !important;
    }

    .login_locatione_close {
        font-size: 21px !important;
    }

    .location_dis {
        font-size: 13px;
    }
}

@media(min-width:0px) and (max-width:1023px) {
    .errorClass {
        font-size: 12px;
    }

    .divcenter {
        min-width: auto !important;
    }

    .login_locatione_main_section .modal-body {
        padding: 20px;
    }

    .login_email_num_btn {
        font-size: 14px;
        padding: 10px 9px;
    }

    .login_email_num_main {
        width: 80%;
        margin: auto;
    }

    .login_locatione_title {
        font-size: 14px;
    }

    .login_locatione_close {
        font-size: 25px;
    }

    .location_dis {
        font-size: 13px;
    }

    .login_locatione_btn {
        padding: 6px 25px;
    }

    .Welcoming_title_login {
        font-size: 14px;
    }
    .FAQ_Terms_of_Use_title {
        font-size: 13px;
    }
}

@media(min-width:1024px) {
    .login_first_section {
        height: 90dvh;
        overflow-y: auto;
    }
}

@media(min-width:1024px) and (max-width:1099px) {
    .errorClass {
        font-size: 12px;
    }
}

@media(min-width:1100px) and (max-width:1199px) {
    .errorClass {
        font-size: 13px;
    }
}

@media(min-width:1200px) and (max-width:1299px) {
    .errorClass {
        font-size: 14px;
    }
}

@media(min-width:0px) and (max-width:1023px) {
    .ReactInputVerificationCode__container {
        width: 100% !important;
        column-gap: 4%;
    }

    .ReactInputVerificationCode__item {
        line-height: 2.5 !important;
        height: 100% !important;
    }

    .loginfont {
        font-size: 20px;
    }

    .loginfontverification {
        font-size: 20px;
    }
}

@media(min-width:320px) and (max-width:423px) {
    .login-button-colour {
        width: 85%;
    }

}

@media(min-width:423px) and (max-width:767px) {
    .login-button-colour {
        width: 85%;
    }

}

@media(min-width:769px) and (max-width:1439px) {
    .login-button-colour {
        width: 85%;
    }

}

@media(min-width:1440px) and (max-width:1919px) {
    .login-button-colour {
        width: 85%;
    }

}

@media(min-width:1920px) and (max-width:2555px) {
    .login-button-colour {
        width: 85%;
    }
}

@media(min-width:2556px) and (max-width:4000px) {
    .login-button-colour {
        width: 85%;
    }

}

@media(min-width:1360px) and (max-width:1400px) {
    .login-button-colour {
        width: 85%;
    }

}




input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}


.errorClass {
    color: red;
    font-size: 14px;
}



@media(min-width:300px) and (max-width:374px) {
    .time_button {
        flex-direction: column;
    }
}

.ReactInputVerificationCode__item {
    border-radius: 19px !important;
}


.width_set {
    width: 80%;
}

@media(min-width:209px) and (max-width:319px) {
    .width_set {
        width: 70%;
    }

}
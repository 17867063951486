.privte_open_modal {
    background: #E4ECE7E5;
    border-radius: 48px;
    padding: 20px 15px;
}

.private_image {
    width: 80px;
}

.title_model {
    text-align: center !important;
    font-size: 26px;
    width: 90%;
}

#privatemode .modal-content {
    width: 100% !important;
}

.model_private_data {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
}

.image_private_center {
    text-align: center;
}

.modal_private_header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeicon {
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
}

.user_name_width_set {
    width: 80%;
}


.add_new_center_col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-loading-skeleton {
    --base-color: #e1e1e1 !important;
}

.card_section_main_sidebar {
    border: 0px solid red;
    position: absolute;
    /* top: 0; */
    top: calc(50% - 62.5px);
    right: 0;
    /* height: 100%; */
    display: flex;
    align-items: center;
    z-index: 9;
}

.card_section_sidebar_center_main {
    background-color: #e5386599;
    border-radius: 20px 0px 0px 20px;
    padding: 4px 4px 4px 7px ;
}

.card_section_chiled{
    width: 31px;
    height: 31px; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1.25px solid rgba(255, 255, 255, 0.7);
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 5px 0px;
}

.card_Chatpage_luSend_sidebar{
    color: white;
    height: 60%;
    width: 60%;

}

.icon_heart_red_sidebar {
    height: 21px !important;
    width: 21px !important;
    cursor: pointer;
    margin-top: 12%;
    margin-left: 8%;
}

.icon_heart_sidebar {
    height: 21px !important;
    width: 21px !important;
    cursor: pointer;
    margin-top: 12%;
    margin-left: 8%;
}

.card_fav_icon_sidebar {
    color: #FFCC33;
    width: 70%;
    height: 70%;
}
.card_unfav_icon_sidebar {
    color: white;
    width: 70%;
    height: 70%;
}

/* .card_section_silebar{
    box-shadow: inset 0px 0px 12px 5px #E53865;
} */

.card_content_bottom{
 background: linear-gradient(180deg, rgba(239, 54, 114, 0) 0.00%, #EF3672 100%);

}


 

@media(min-width:280px) and (max-width:768px) {
    .model_private_data {
        flex-direction: column;
    }

    .title_model {
        font-size: 18px;
    }

    .private_image {
        width: 60px;
    }

    .card_section_chiled {
        width: 25px;
        height: 25px;
    }
}
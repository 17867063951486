.HelpSupport_Popular_Articles_main{
    border-radius: 14px;
    background-color: #FFFCFD !important;
    padding: 2% 6%;

}

.HelpSupport_page_popular_Articles {
    font-size: 24px;
    font-weight: 600;
}
.HelpSupport_page_Help_title{
    font-size: 20px;
    font-weight: 600;
    color: #E53865 !important;
}
.HelpSupport_page_Learn_more{
    background-color: #E53865 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    width: 40%;
    color:#FFFFFF;
    padding: 8px 0% !important;
}
.HelpSupport_page_Learn_more:focus{
    border: none !important;
    box-shadow: none !important;
}

@media(min-width:280px) and (max-width:767px) {
    .HelpSupport_page_popular_Articles {
        font-size: 21px;
    }
    .HelpSupport_page_Help_title{
        font-size: 14px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .HelpSupport_page_popular_Articles {
        font-size: 23px;
    }
    .HelpSupport_page_Help_title{
        font-size: 16px;
    }
}

@media(min-width:1023px) and (max-width:1199px) {
    .HelpSupport_page_popular_Articles {
        font-size: 20px;
    }
    .HelpSupport_page_Help_title{
        font-size: 16px;
    }
}
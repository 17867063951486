.profiletext{
    font-weight: 600;
    font-size: 30px;
}
.group6set{
    width: 12%;
    top: 63%;
    right: 65%;
}
.group5set{
    width: 23%;
    top: 53%;
    left: 67%;
}
.group15set{
    width: 10%;
    top: 17%;
}
/* .ellipse4set{
    width: 99vw;
    top: 2%;
    height: 90vh ;
}
.ellipse5set{
    width: 99vw;
    top: 0;
    height: 90vh ;

} */
.ellipse4set {
    width: 93%;
    top: 2%;
    height: 95%;
}
.ellipse5set {
    width: 93%;
    top: 0;
    height: 95%;
}
.profile-cover-button {
    bottom: 0px;
    right: 0px;
    background-color: transparent;
    width: 32px;
    height: 31px;
    border: none;
  }
  
.profile-cover-button img {
    width: 100%;
    height: auto;
  }
  .profileimgset{
    border-radius: 20px;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
  .ellipse12set{
    width: 1%;
    right: 75%;
    top: 23%;
  }
.ellipse13set{
    width: 1%;
    right: 17%;
    top: 22%;
}
.widthcustom{
    max-width: 50% !important;
}
.ellipse14set{
        width: 3%;
    top: 17%;
    right: 27%;
}
.centerinputdetlis{
    display: flex;
    align-content: center;
    align-items: center;
}
.confirmbtn{
    background-color: #EF3672 !important;
    color: white !important;
    padding: 10px 26px;
    border: 0px !important;
    border-radius: 26px;
}
.form-check-input:checked {
    background-color: #EF3672 !important;
    border-color: #EF3672 !important;
}
.form-check-input:focus {
    box-shadow:none !important;
}
.bg-remove{
    background-color: transparent !important;
    cursor: pointer;
}
.selecteditem {
    background-color:#EF3672;
    color: white !important;
}
.unselecteditem {
    border: 1px solid #EF3672 ;
    color: #EF3672 !important;
}
.fonthere{
    color: rgba(0, 0, 0, 0.5) !important;
}
  .borderclass {
    width: auto !important;
    border: 1px solid;
    border-radius: 19px;
    padding:6px 13px;
    cursor: pointer;
  }

  .set_profiledetails_pages{
    z-index: 10;
    position: relative;
  }
  
  .back_profileicon{
    z-index: 1;
  }
    
.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url(data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%272%27 fill=%27%23EF3672%27/></svg>) !important;
    background-color: transparent !important;
    /* padding: 10px; */
}
.form-check-input:checked {
    border: 1px solid #EF3672 !important;
} 
.form-check-input {
    --bs-form-check-bg: transparent  !important;
    background-color:transparent  !important;
    border: 1px solid #EF3672 !important;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) and (min-width: 200px){
    .widthcustom{
        max-width: 100% !important;
    }
  }

  .click_back_profilepage{
    z-index: 1;
    cursor: pointer;
  }

  .react-datepicker__input-container input{
    border: none;
    height: 16px;
    width: 100%;
    background-color: white;
    color: black;
  }

  .react-datepicker__input-container input:focus-visible{
    outline: none !important;
  }

  .react-datepicker__header{
  background-color: #EF3672 !important;
  }
  .react-datepicker__current-month{
    color:white!important
  }

  .react-datepicker__day--selected{
  background-color: #EF3672 !important;
  border-radius: 50% !important;
  }
  .react-datepicker__day-names {
   padding-top: 6px;
  }

  .react-datepicker__calendar-icon{
    padding: 0px !important;
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: #E4D9D8 !important;
    border-radius: 50% !important;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #E4D9D8 !important;
    border-radius: 50% !important;
  }
  .react-datepicker-popper{
   z-index: 99;
  }


.disabled_class {
  background-color: #E9ECEF !important;
}

.disabled_class input {
  background-color: #E9ECEF !important;
}

.payment_method_floatingLabel.disabled_class label::after {
  background-color: #E9ECEF !important;
}

.react-datepicker__year-select {
  width: 70px;
  border-radius: 5px;
  outline: none;
  border: none;
}

.react-datepicker__month-select {
  border-radius: 5px;
  outline: none;
  border: none;
}
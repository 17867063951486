.Notifications_main_section {
    height: 80vh;    
    height: calc(80dvh - var(--banner-div-height));
    position: relative;
    z-index: 1;
    background-color: rgba(228, 236, 231, 0.6);
    overflow-y: scroll;
}

.Settingnotifications_siderbar_main {
    height: 80vh;    
    height: calc(80dvh - var(--banner-div-height));
    background-color: rgba(255, 255, 255, 1);
    overflow-y: scroll;
}

.Settingnotifications_Setting_title {
    font-size: 30px;
    font-weight: 600;
    color: black;
}

.Settingnotifications_icon_main_section {
    height: 40px;
    width: 40px;
    background-color: #E53865;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Settingnotifications_icon {
    height: 65%;
    width: 65%;
    color: white;
}

.active_notifiction{
    /* font-weight: 100; */
    font-weight: bolder;
    font-size: 19px;
    /* font-size: 20px; */
    color: black !important;
    text-decoration: none !important;
    /* font-family: "Poppins-Bold";     */
}

.Setting_sidebar_menu_Privacy_to_Report{
    font-weight: bolder;
    font-size: 19px;
    color: black !important;
    text-decoration: none !important;
}

.Setting_sidebar_menu_Delete_Logout{
    font-weight: 600;
    font-size: 19px;
    color: #E53865 !important;
    text-decoration: none !important;
}

.Settingnotifications_Allow_notification_main { 
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 20px 40px 0px rgba(59, 64, 86, 0.15);
    padding: 2% 3%;
    border-radius: 10px !important;
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e) !important;
    width: 60px !important;
    height: 30px !important;
    margin-left: -3.5em !important;
    /* background-image: var(--bs-form-switch-bg); */
    /* background-position: left center; */
    border-radius: 50px !important;
    /* transition: background-position .15s ease-in-out; */
    margin-top: -1% !important;
    cursor: pointer;
}

.form-switch .form-check-input:checked {
    /* background-position: right center; */
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;
    cursor: pointer;
    border: none !important;
}

.Settingnotifications_label_name {
    font-weight: 500 !important;
    font-size: 20px;
}

.Settingnotifications_E_mail_Privacy {
    font-size: 25px;
    font-weight: 500;
}
#dropdown-basic-button{
    background-color: white !important;
    border-radius: 50px !important;
    box-shadow: none !important;
    color: black;
    border: none !important;
    /* content: url("../../../images/Setting/arrow-white-dropdown.png") !important; */
    border-top: 0px solid !important;
    border-right: 0px solid transparent !important;
    border-bottom: 0px !important;
    border-left: 0px solid transparent !important;
}
#dropdown-basic-button:focus{
    background-color: white !important;
    border-radius: 50px !important;
    box-shadow: none !important;
    color: black;
    border: none !important;
}

.dropdown-toggle{
    /* background-color: #E53865 !important;
    border-radius: 50px !important;
    box-shadow: none !important;
    color: white;
    border: none !important; */
}


@media(min-width:280px) and (max-width:767px) {

    .Notifications_main_section {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .Settingnotifications_siderbar_main {
        height: 70vh;        
        height: calc(70dvh - var(--banner-div-height));
    }
    .notifiction_setting_mobile_title{
        font-size: 21px;
        font-weight: 800;
    }
    .Settingnotifications_label_name {
        font-size: 16px;
        width: 80%;
    }
    .Settingnotifications_E_mail_Privacy {
        font-size: 21px;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .notifiction_setting_mobile_title{
        font-size: 23px;
        font-weight: 800;
    }  
    .Notifications_main_section {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }
}
@media(min-width:1023px) and (max-width:1199px) {
    .Settingnotifications_Setting_title {
        font-size: 24px;
    }
    .Settingnotifications_icon_main_section {
        height: 35px;
        width: 35px;
    }
    .Setting_sidebar_menu_Privacy_to_Report {
        font-size: 16px;
    }
    .active_notifiction {
        font-size: 16px;
    }
    .Setting_sidebar_menu_Delete_Logout {
        font-size: 16px;
    }
    .Settingnotifications_E_mail_Privacy {
        font-size: 19px;
    }
    .Settingnotifications_label_name {
        font-size: 17px;
    }
}


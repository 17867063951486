.user_Detailspage_main_section {
    position: relative;
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
    z-index: 9;
    overflow-y: scroll;
    /* border: 1px solid red; */
}

.user_Detailspage_main_section::-webkit-scrollbar {
    display: none;
}

.user_Detailspage_user_img_section {
    width: 75%;
    /* height: 95%; */
    /* min-height: 95% !important; */
    /* max-height: 95% !important; */
    border-radius: 15px;
    overflow: hidden;
    aspect-ratio: 1.5/2;
}

.user_Detailspage_user_img {
    width: 100%;
    height: 100%;
}

.uppercaseInput {
    text-transform: uppercase
}

.user_Detailspage_icon_section {
    height: 52px;
    width: 52px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(232, 230, 234, 1);
}

.user_Detailspage_luSend {
    color: #E53865;
    height: 50%;
    width: 50%;
}


.user_all_Details_secondsection {
    /* padding: 20px 35px; */
    /* padding: 20px 20px 20px 40px; */
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    height: calc(80vh - 24px - var(--banner-div-height));
    overflow-y: scroll;
}

.user_view_details_pageseco {
    padding: 10px 35px;
}

.user_edit_details_pageseco {
    padding: 20px 35px;
}

.email_number_birth_visible {
    color: #E53865;
}

/* .user_all_Details_secondsection_edit_page_user {
    height: calc(80vh + 102px - 24px - var(--banner-div-height)) !important;
} */

.user_Detailspage_all_Details_secondsection {
    padding: 5px 40px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    height: calc(80vh - 24px - var(--banner-div-height));
    overflow: scroll;
}


.user_Detailspage_location_title {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins-Bold";
}

.user_Detailspage_location_name {
    font-size: 17px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);

}

.user_Detailspage_kilometer_section {
    background-color: #F8E8EB;
    border-radius: 5px;
    padding: 7px 14px;
    width: max-content;
    display: flex;
    align-items: center;
}

.user_Detailspage_grlocation {}

.user_Detailspage_kilometer_title {
    color: #E53865;
    font-weight: 700;
    font-family: "Poppins-Bold";
}

.user_Detailspage_About {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins-Bold";
}

.user_Detailspage_info {
    font-size: 15px;
    font-weight: 700;
    font-family: "Poppins-Bold";
}

.user_Detailspage_about_introduction {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
}

.user_Detailspage_Read_more {
    color: #E53865;
    font-weight: 700;
    font-size: 17px;
    cursor: pointer;
}

.heart_star_main_section {
    width: 100%;
    position: absolute;
    bottom: -8%;
    display: flex;
    left: 0%;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.close_icon_section {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.07);
    height: 55px;
    width: 55px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.close_icon_section img {
    height: 40%;
    width: 40%;
}

.heart_icon_section {
    background-color: rgb(255, 255, 255);
    /* box-shadow: 0px 15px 15px 0px rgba(233, 64, 87, 0.2); */
    height: 70px;
    width: 70px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.heart_icon_section img {
    height: 50%;
    width: 50%;
}

.star_icon_section {
    background-color: rgba(255, 255, 255, 1);
    /* box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.07); */
    height: 55px;
    width: 55px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.imglock {
    position: absolute;
    width: 10px;
    bottom: 5px;
    right: 5px;
}

.star_icon_section img {
    height: 50%;
    width: 50%;
}

.UserDetails_gallery_main_section {
    width: 76%;
}

.UserDetails_title_Gallery {
    font-size: 16px;
    font-weight: 700;
    color: #000000;

}

.UserDetails_title_Seeall {
    font-size: 14px;
    font-weight: 700;
    color: black !important;
    cursor: pointer;
}

.UserDetails_title_name {
    font-size: 30px;
}

.UserDetails_partner_name {
    font-size: 22px;
}

.UserDetails_gallery_img_section {
    height: 80px;
    width: 24%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.usergallery_seco_section {
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.UserDetails_gellery_img {
    height: 100%;
    width: 100%;
}

video.UserDetails_gellery_img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user_Detailspage_Interests_section {
    border-radius: 25px;
    Border: 1px solid #EF3672;
    Padding: 4px 15px 4px 15px;
    color: #EF3672;
}

.user_Detailspage_Interests_section.selected {
    background-color: #EF3672;
    color: #FFFFFF;
}

.user_Detailspage_Gender_Age_mainsection {
    width: 100%;
    height: auto;
    background-color: #F7E8EA;
    border-radius: 10px;
}

.user_Detailspage_Gender_Age_icon_main {
    width: 25%;

}

.use_detai_view_single_user {
    padding: 0px 4px;
}

.user_Detailspage_Gender_Age_icon_main_new {
    width: 15%;

}

.user_Detailspage_Gender_Age_icon_section {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #E53865;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.user_Detailspage_Gender_Age_Favourites_title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
}

.user_Detailspage_Gender_Favourites2_img {
    width: 60%;
    height: 60%;
}

.user_Detailspage_Calendar_img {
    width: 50%;
    height: 50%;
}

.user_Detailspage_Gender_Age_Favourites_value {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

.user_Detailspage_Report_User_main_section {
    position: relative;
    min-height: 60px;
}

.user_Detailspage_Report_User_section {
    bottom: 0;
    position: absolute;
    right: 0%;
    min-height: 60px;
}

.user_Detailspage_Report_User_btn {
    background-color: transparent;
    padding: 7px 7px;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.15);
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
}

.Userdetails_modal_main_section {
    background-color: #FFFFFF;
    width: 80%;
    margin: auto;
    padding: 25px;
    border-radius: 48px;
    overflow: hidden;
}

.Userdetails_modal_title {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.Userdetails_modal_report_resone {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);

}

.Userdetails_modal_selector {
    box-shadow: 0px 20px 40px 0px rgba(59, 64, 86, 0.15) !important;
    width: 90% !important;
    border-radius: 50px !important;
    color: #00000099 !important;
    padding: 6px 36px 6px 5% !important;
    background-position: right 6% center !important;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.Userdetails_modal_textarea {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    width: 90% !important;
    margin: auto;
    border-radius: 10px !important;
    resize: none !important;
    min-height: 50px !important;
}

.Userdetails_modal_textarea::placeholder {
    padding-top: 0% !important;
    color: rgba(0, 0, 0, 0.5) !important;

}

.Userdetails_modal_textarea:focus {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.Userdetails_modal_btn_Report_user {
    background-color: #E53865 !important;
    border: 1px solid #E53865 !important;
    width: 70% !important;
    color: #FFFFFF !important;
    border-radius: 80px !important;
    padding: 8px 0px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.Userdetails_modal_btn_Report_user:focus {
    box-shadow: none !important;
}

.coupons_apply_btn {
    background-color: #E53865 !important;
    border: 1px solid #E53865 !important;
    width: 50% !important;
    color: #FFFFFF !important;
    border-radius: 80px !important;
    padding: 7px 0px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin: 0px 10px !important;
}

.coupons_apply_btn:focus {
    box-shadow: none !important;
}

.discount_text {
    color: green;
}

.Userdetails_modal_btn_Cancel {
    background-color: transparent !important;
    border: 1px solid black !important;
    width: 70% !important;
    color: black !important;
    border-radius: 80px !important;
    padding: 8px 0px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.Userdetails_modal_btn_Cancel-sm {
    background-color: transparent !important;
    border: none !important;
    width: 24% !important;
    color: #E53865 !important;
    border-radius: 80px !important;
    padding: 8px 0px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-left: 5px !important;
}

.coupon_model_shadow {
    box-shadow: 7px 26px 25px 6px #100f0f5e;
}

.Userdetails_modal_btn_Cancel:focus {
    box-shadow: none !important;
}

.last_seen_main_section {
    /* background: white; */
    font-size: 18px;
    border-radius: 5px;
}

.last_seen_dot_section {
    height: 10px;
    width: 10px;
    background: #E53865;
    border-radius: 50px;
}

.last_seen_dot_section_active {
    height: 10px;
    width: 10px;
    background: #3BB143;
    border-radius: 50px;
}

.custom .dropdown-toggle::after {
    content: none !important;
    display: none !important;
}

.custom .dropdown-toggle {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.customMedia .dropdown-toggle::after {
    content: none !important;
    display: none !important;
}

.customMedia .dropdown-toggle {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
}

/* .Dropdown_Menu_user_details{
    transform: translate(150px, 28px) !important;
} */

.user_detailes_page_tooltip_btn {
    background-color: transparent !important;
    color: #000000 !important;
    border: none !important;
    box-shadow: none !important;
}

.user_detailes_page_tooltip_btn:focus {
    background-color: transparent !important;
    color: #000000 !important;
    border: none !important;
    box-shadow: none !important;
}

.user_detailes_page_tooltip_icon {
    font-size: 20px;
}

.UserDetails_title_Gallery_main_section {
    width: 74%;
}

#userdetaile_switch .form-switch .form-check-input {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e) !important;
    width: 60px !important;
    height: 30px !important;
    margin-left: -3.5em !important;
    /* background-image: var(--bs-form-switch-bg); */
    /* background-position: left center; */
    border-radius: 50px !important;
    /* transition: background-position .15s ease-in-out; */
    margin-top: -1% !important;
    cursor: pointer;
    border-color: white !important;

}

#userdetaile_switch .form-switch .form-check-input:checked {
    /* background-position: right center; */
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23EF3672%27/%3e%3c/svg%3e) !important;
    cursor: pointer;
    border: none !important;
    background-color: white !important;
    border-color: #EF3672 !important;

}

.user_info_img_table {
    width: 10%;
}

.user_info_data_table {
    width: 90%;
}

.user_info_data_table_catag {
    color: rgba(0, 0, 0, 0.6);
}

.user_Detailspage_location_para_main {
    width: 70%;
}

.user_Detailspage_kilometer_main_section {
    width: 30%;
    display: flex;
    /* justify-content: flex-end;
    align-items: center; */
    align-items: flex-end;
    flex-direction: column;
}

.userDetail_premium_link {
    color: #EF3672;
    cursor: pointer;
    width: max-content;
}

.userd_Last_seen_online {
    font-size: 17px;
}

.play_icon_userdetail_silde {
    position: absolute;
    border-radius: 50%;
    background-color: #EF3672B2;
    height: 20px;
    width: 20px;
    color: #FFFFFFB2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}


.userdetaile_tooltip>.tooltip-inner {
    max-width: 400px;
}

@media screen and (max-width: 280px) {
    .close_icon_section {
        height: 42px;
        width: 42px;
    }

    .star_icon_section {
        height: 42px;
        width: 42px;
    }

    .heart_icon_section {
        height: 57px;
        width: 57px;
    }

    .UserDetails_title_name {
        font-size: 25px;
    }

    .Allow_Private_lable {
        font-size: 12px !important;
    }

    .UserDetails_gallery_img_section {
        height: 60px !important;
    }

}

@media(min-width:280px) and (max-width:767px) {
    .user_Detailspage_main_section {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .user_all_Details_secondsection {
        padding: 15px 22px;
        height: auto;
    }

    .user_view_details_pageseco {
        padding: 10px 22px;
    }

    .user_edit_details_pageseco {
        padding: 15px 22px;
    }

    /* .user_all_Details_secondsection_edit_page_user {
        height: auto !important;
    } */

    .user_Detailspage_all_Details_secondsection {
        padding: 5px 22px;
        height: auto;
    }

    .user_Detailspage_kilometer_section {
        background-color: #F8E8EB;
        border-radius: 5px;
        padding: 7px 13px;
    }

    .user_Detailspage_icon_section {
        height: 40px;
        width: 40px;
    }

    .UserDetails_gallery_img_section {
        height: 70px;
        width: 24%;
    }

    .user_Detailspage_location_title {
        font-size: 18px;
    }

    .user_Detailspage_location_name {
        font-size: 15px;
    }

    .user_Detailspage_About {
        font-size: 18px;
    }

    .user_Detailspage_about_introduction {
        font-size: 16px;
    }

    .user_Detailspage_Read_more {
        font-size: 15px;
    }

    .user_Detailspage_Report_User_btn {
        padding: 8px 7px;
        font-size: 11px;
    }

    .Userdetails_modal_main_section {
        background-color: #FFFFFF;
        width: 100%;
        margin: auto;
        padding: 25px;
        border-radius: 48px;
        overflow: hidden;
    }

    .Userdetails_modal_selector {
        box-shadow: 0px 20px 40px 0px #3B405626 !important;
        border: 1px solid white !important;
        width: 90% !important;
        border-radius: 13px !important;
        color: #00000099 !important;
        padding: 6px 36px 6px 5% !important;
        background-position: right 6% center !important;
        margin: auto;
    }

    .user_Detailspage_Report_User_section {
        right: calc(50% - 55px);
    }

    .heart_star_main_section {
        bottom: -10%;
        width: 100%;
        left: 0;
    }

    .user_Detailspage_Gender_Age_icon_main {
        width: 50%;
    }

    .user_Detailspage_Gender_Age_icon_main_new {
        width: 50%;
    }

    .user_Detailspage_location_para_main {
        width: 50%;
    }

    .user_Detailspage_kilometer_main_section {
        width: 50%;
    }

    .user_Detailspage_kilometer_title {
        font-size: 14px;
    }

    .userd_Last_seen_online {
        font-size: 12px;
    }

    .userdetila_switch_gallery {
        width: 90%;
    }

    .Allow_Private_lable {
        font-size: 12px !important;
    }

    .UserDetails_gallery_main_section {
        width: 90%;
    }

    .UserDetails_title_Gallery_main_section {
        width: 90%;
    }

    .userdetaile_tooltip>.tooltip-inner {
        max-width: 300px;
    }
}

@media(min-width:715px) and (max-width:717px) {
    .heart_star_main_section {
        bottom: -6%;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .user_Detailspage_main_section {
        /* height: calc(73dvh - var(--banner-div-height)); */
        height: calc(82dvh - var(--banner-div-height));
    }

    .user_Detailspage_icon_section {
        height: 40px;
        width: 40px;
    }

    .UserDetails_gallery_img_section {
        height: 50px;
        width: 24%;
    }

    .user_Detailspage_location_title {
        font-size: 18px;
    }

    .user_Detailspage_location_name {
        font-size: 15px;
    }

    .user_Detailspage_About {
        font-size: 18px;
    }

    .user_Detailspage_about_introduction {
        font-size: 16px;
    }

    .user_Detailspage_Read_more {
        font-size: 15px;
    }

    .user_Detailspage_Gender_Age_Favourites_title {
        font-size: 14px;
    }

    .user_Detailspage_Gender_Age_Favourites_value {
        font-size: 15px;
    }

    .user_Detailspage_Report_User_btn {
        font-size: 14px;
    }

    .user_Detailspage_Report_User_section {
        right: calc(50% - 55px);
    }

    .heart_star_main_section {
        bottom: -10%;
        width: 100%;
        left: 0;
    }

    .close_icon_section {
        height: 45px;
        width: 45px;
    }

    .heart_icon_section {
        height: 60px;
        width: 60px;
    }

    .star_icon_section {
        height: 45px;
        width: 45px;
    }

    .user_Detailspage_kilometer_title {
        font-size: 14px;
    }

    .user_Detailspage_location_para_main {
        width: 53%;
    }

    .user_Detailspage_kilometer_main_section {
        width: 47%;
    }

    .user_Detailspage_Gender_Age_icon_main_new {
        width: 30%;
    }

    .userd_Last_seen_online {
        font-size: 16px;
    }

    .userdetaile_tooltip>.tooltip-inner {
        max-width: 300px;
    }
}

@media(min-width:1024px) and (max-width:1199px) {
    .user_Detailspage_Gender_Age_Favourites_title {
        font-size: 12px;
    }

    .user_Detailspage_Gender_Age_Favourites_value {
        font-size: 13px;
    }

    .UserDetails_gallery_img_section {
        height: 50px;
    }

    .heart_star_main_section {
        bottom: -10%;
    }

    .user_Detailspage_Gender_Age_icon_section {
        width: 50px;
        height: 50px;
    }

    .close_icon_section {
        height: 45px;
        width: 45px;
    }

    .heart_icon_section {
        height: 60px;
        width: 60px;
    }

    .star_icon_section {
        height: 45px;
        width: 45px;
    }
}


.new_report_btn {
    box-shadow: 0px 10px 20px 0px #0000001A;
    color: white;
    background-color: #E53865;
    border: none;
    border-radius: 24px;
    padding: 7px 9px;
}
.Paymentmethod_main_section {
    position: relative;
    z-index: 9;
    height: 80vh;
    height: calc(80dvh - var(--banner-div-height));
    overflow-y: scroll;
}

.Paymentmethod_main_section::-webkit-scrollbar {
    display: none;
  }

.Select_Payment_Method {
    font-size: 35px;
    font-family: 'Poppins-Regular';
}

.Choose_Best_Payment_Method {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
    font-family: 'Poppins-Regular';
}
.Payment_Details{
    font-size: 35px;
    font-family: 'Poppins-Regular';
}
.continue_btn{
    border-radius: 8px;
    border: none !important;
    background-color: #EF3672;
    color: white;
    padding: 6px 42px;
}
.payment_method_form:focus{
    border-color:#EF3672 !important;
    box-shadow: none !important;
}

.payment_method_form{
    border-color:#EF3672 !important;
    box-shadow: none !important;
    height: 50px;
    border-radius: 15px !important;
}

.payment_method_form::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.title_lable{
    font-family: 'Poppins-Regular' !important;
    font-size: 20px;
}

.react-datepicker-wrapper{
    width: 100%;
}
 
.VisaElectron{
    width: auto;
    height: 45px;
}
.PayPal{
    width: auto;
    height: 45px;
}
.MasterCard{
    width: auto;
    height: 45px;
}
.GooglePay{
    width: auto;
    height: 45px;
}

.payment_method_form::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
    font-family: 'Poppins-Regular' !important;
    font-size: 15px !important;
  }
  

  .form-floating>.form-control, .form-floating>.form-control-plaintext{
    border-radius: 15px;
  }
  

  .no-spinner::-webkit-inner-spin-button,
  .no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-floating>.form-control, .form-floating>.form-control-plaintext:focus{
    border-color:#EF3672 !important;
    box-shadow: none !important;
} 
 
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label{
    color: #EF3672 !important;
    margin-top: -1.5% !important;
    font-size: 14px !important;
}
 

.form-floating>.form-control:placeholder-shown ~label{
    color: rgba(0, 0, 0, 0.5);
    margin-top: -1% !important;
    font-size: 15px;
    z-index: 0 !important;
}

.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
    height: 50px !important;
    min-height:50px !important;
}
.form-floating>.form-select {
    border-color: #EF3672 !important;
    box-shadow: none !important;
    border-radius: 15px !important;
    padding-top: 16px !important;
    padding-bottom: 0px !important;
}

.form-floating>label {
    z-index: 0 !important;
}

@media(min-width:280px) and (max-width:767px) {
    .Select_Payment_Method {
        font-size: 24px;
    }
    
    .Choose_Best_Payment_Method {
        font-size: 13px;
         
    }
    .Payment_Details{
        font-size: 24px;
    }
    .Paymentmethod_main_section {
        height: 70vh;
        height: calc(70dvh - var(--banner-div-height))
    }
    .title_lable {
        font-family: 'Poppins-Regular' !important;
        font-size: 14px;
    }
}
@media(min-width:768px) and (max-width:1023px) {

}
.DynamicPage_content_main{
    height: calc(80dvh - var(--banner-div-height)); 
    overflow-y: scroll;
}
.ql-editor img {
 width: auto;
 max-width: 100%;
}

@media(min-width:280px) and (max-width:767px) {
    .DynamicPage_content_main{
        height: calc(82dvh - var(--banner-div-height));
    }
}   
@media(min-width:768px) and (max-width:1023px) {
    .DynamicPage_content_main{
        height: calc(81dvh - var(--banner-div-height));
    }
}
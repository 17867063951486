
.Block_and_delete_chat_title{
        margin-top: 2px;
}

@media(min-width:280px) and (max-width:767px) {
    .Terms_Policies_condition{
        font-size: 13px;
    }
    .Report_Problem_page_introduction{
        font-size: 13px;
    }
}
/* 
@media(min-width:768px) and (max-width:1023px) {
     
} */
